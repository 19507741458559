import clsx from 'clsx';
import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import { fromMarkdown } from 'mdast-util-from-markdown';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { MainActionCreators } from '../state/reducer';
import * as mdUtil from '../util-md';

import { useAuthedApiClient } from './hooks/useApiClient';
import useApiDo from './hooks/useApiDo';
import useFeedEntryRefresher from './hooks/useFeedEntryRefresher';
import useMediaUploader, { UploaderStatus } from './hooks/useMediaUploader';
import CloseCircleIcon from './icon/CloseCircleIcon';
import { useKeyPress } from './KeyPressContext';
import Alert from './lib/Alert';
import Badge from './lib/Badge';
import Button from './lib/Button';
import Collapse from './lib/Collapse';
import FilePicker from './lib/FilePicker';
import InputLabel from './lib/InputLabel';
import Modal from './lib/Modal';
import Spinner from './lib/Spinner';
import TextInput, { TextInputFooter } from './lib/TextInput';
import PostBoxesEditor, { Box, PostBoxesEditorMethods } from './PostBoxesEditor';

const PostModal = (props: { feedId: string; closeModal: () => void; isForeground: boolean }) => {
  return (
    <PostInnerModal
      feedId={props.feedId}
      closeModal={props.closeModal}
      isForeground={props.isForeground}
      formMode={{ kind: 'new' }}
    />
  );
};

type PostFormMode =
  | { kind: 'new' }
  | {
      kind: 'update';
      entryId: string;
      initContent: string;
      initTitle?: string;
      contentVideoSpecs?: api.feed.IContentVideoSpec[];
      featuredImage?: api.feed.IImage;
    }
  | {
      kind: 'cpc-update';
      entryId: string;
      initContent: string;
      initTitle?: string;
      contentVideoSpecs?: api.feed.IContentVideoSpec[];
      featuredImage?: api.feed.IImage;
    };

const PostInnerModal = (props: {
  feedId: string;
  closeModal: () => void;
  isForeground: boolean;
  formMode: PostFormMode;
}) => {
  const dispatch = useDispatch();
  const apiClient = useAuthedApiClient();
  const { refreshFeedEntry } = useFeedEntryRefresher(apiClient);

  useKeyPress(
    ['u', 'Escape'],
    () => {
      props.closeModal();
    },
    !props.isForeground,
    10,
  );

  const [inFlight, setInFlight] = useState(false);
  const [title, setTitle] = useState(props.formMode.kind === 'new' ? '' : props.formMode.initTitle ?? '');

  const { apiDo: apiFeedEntryAddPost, okToast, errToast } = useApiDo(apiClient, apiClient.feedEntryAddPost);
  const { apiDo: apiFeedEntryUpdatePost } = useApiDo(apiClient, apiClient.feedEntryUpdatePost);
  const { apiDo: apiFeedEntryCpcUpdate } = useApiDo(apiClient, apiClient.feedEntryCpcUpdate);

  const [topBoxContent, setTopBoxContent] = React.useState('');

  // Only updated on major changes by PostBoxesEditor
  const [boxes, setBoxes] = React.useState<Box[]>([]);

  const titleRequired =
    props.formMode.kind === 'cpc-update'
      ? 'yes-cpc'
      : boxes.filter(box => box.kind === 'slide-divider').length >= 1
        ? 'yes-prexo'
        : boxes.length > 1
          ? 'yes-media'
          : mdUtil.isTitleRequired(topBoxContent);
  const isTitlePlaintext = mdUtil.isMarkdownPlaintext(fromMarkdown(title));

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!postEditorRef.current) {
      return;
    }
    const editorResult = postEditorRef.current.getMarkdownContent();
    if (editorResult.kind === 'not-ready') {
      errToast('Please wait while media uploads...');
      return;
    } else if (editorResult.kind === 'need-fix') {
      errToast('Please fix error');
      return;
    }
    // featuredImageBoxRef is not set if this isn't a new post.
    if (featuredImageBoxRef.current && featuredImageBoxRef.current.getUploaderStatus().kind === 'uploading') {
      errToast('Please wait for featured image to upload');
      return;
    }
    setInFlight(true);
    const contentFull = titleRequired !== 'no' ? `# ${title}\n\n${editorResult.content}` : editorResult.content;
    if (props.formMode.kind === 'new') {
      apiFeedEntryAddPost(
        {
          content: contentFull,
          feed_id: props.feedId,
          media_upload_id: featuredImageUploadId ?? undefined,
        },
        {
          onFinally: () => setInFlight(false),
          onResult: res => {
            if (res['.tag'] !== 'new_entry') {
              throw Error('Unexpected: New post not a new entry');
            }
            okToast('Posted');
            dispatch(MainActionCreators.prependFeedEntry(props.feedId, res.new_entry));
            refreshFeedEntry(props.feedId, res.new_entry, 5, entry => entry.md['.tag'] !== 'not_ready');
            props.closeModal();
          },
          onRouteErr: (err, defaultErrToast) => {
            if (err['.tag'] === 'bad_content') {
              errToast('Post failed', 'Post contents invalid');
            } else if (err['.tag'] === 'bad_image') {
              errToast('Post failed', 'Image is invalid');
            } else if (err['.tag'] === 'slow_down') {
              errToast('Slow down', `Try again in ${cfe.Formatter.secondsToHoursMinsSecsStr(err.wait_period)}`);
            } else {
              defaultErrToast();
            }
          },
        },
      );
    } else if (props.formMode.kind === 'update') {
      apiFeedEntryUpdatePost(
        {
          content: contentFull,
          entry_id: props.formMode.entryId,
          featured_image_update: featuredImageUploadId
            ? { '.tag': 'set', set: featuredImageUploadId }
            : initialFeaturedImageRemoved
              ? { '.tag': 'remove' }
              : { '.tag': 'no_op' },
        },
        {
          onFinally: () => setInFlight(false),
          onResult: res => {
            okToast('Updated post');
            dispatch(MainActionCreators.updateFeedEntry(props.feedId, res.updated_entry));
            props.closeModal();
          },
          onRouteErr: (err, defaultErrToast) => {
            if (err['.tag'] === 'bad_content') {
              errToast('Post failed', 'Post contents invalid');
            } else {
              defaultErrToast();
            }
          },
        },
      );
    } else if (props.formMode.kind === 'cpc-update') {
      apiFeedEntryCpcUpdate(
        {
          content: contentFull,
          entry_id: props.formMode.entryId,
          featured_image_update: featuredImageUploadId
            ? { '.tag': 'set', set: featuredImageUploadId }
            : initialFeaturedImageRemoved
              ? { '.tag': 'remove' }
              : { '.tag': 'no_op' },
          if_new_make_default_view: false,
        },
        {
          onFinally: () => setInFlight(false),
          onResult: res => {
            okToast('Updated post');
            dispatch(MainActionCreators.updateFeedEntry(props.feedId, res.updated_entry));
            props.closeModal();
          },
          onRouteErr: (err, defaultErrToast) => {
            if (err['.tag'] === 'bad_content') {
              errToast('Post failed', 'Post contents invalid');
            } else {
              defaultErrToast();
            }
          },
        },
      );
    }
  };

  const postEditorRef = React.useRef<PostBoxesEditorMethods | null>(null);
  const featuredImageBoxRef = React.useRef<FeaturedImageBoxMethods | null>(null);
  const [featuredImageUploadId, setFeaturedImageUploadId] = React.useState<string | null>(null);
  const [initialFeaturedImageRemoved, setInitialFeaturedImageRemoved] = React.useState(false);

  const presetFeaturedImages: PresetFeaturedImage[] = [];
  for (const box of boxes) {
    if (box.kind === 'image' && box.uploadId && box.tempMediaUrl) {
      presetFeaturedImages.push({ uploadId: box.uploadId, tempMediaUrl: box.tempMediaUrl });
    }
  }
  return (
    <Modal.Container show={props.isForeground} close={props.closeModal}>
      <Modal.Header>
        <Modal.Heading1>
          {props.formMode.kind === 'new' ? 'New' : 'Update'}{' '}
          {props.formMode.kind === 'cpc-update' ? 'Native Experience' : 'Post'}
        </Modal.Heading1>
      </Modal.Header>
      <Modal.Body gutter>
        <div className="tw-pt-6" />
        <form onSubmit={handleSubmit}>
          <Collapse open={titleRequired !== 'no'}>
            <div className="tw-mb-4">
              <InputLabel>Title</InputLabel>
              {isTitlePlaintext ? (
                titleRequired !== 'yes-prexo' && titleRequired !== 'yes-cpc' ? (
                  <Alert variant="info" className="tw-mb-2">
                    {titleRequired === 'yes-complex'
                      ? "Title required because of the post's use of markdown syntax"
                      : titleRequired === 'yes-length'
                        ? "Title required because of the post's length"
                        : titleRequired === 'yes-line-count'
                          ? "Title required because of the post's line count"
                          : titleRequired === 'yes-media'
                            ? 'Title required because of the use of media'
                            : null}
                  </Alert>
                ) : null
              ) : (
                <Alert variant="danger" className="tw-mb-2">
                  Title must be plain text
                </Alert>
              )}
              <TextInput onChange={e => setTitle(e.currentTarget.value)} value={title} maxLength={150} />
              <TextInputFooter value={title} maxLength={150} left="Required" />
            </div>
          </Collapse>
          <PostBoxesEditor
            ref={postEditorRef}
            prexoOk
            initMarkdownContent={
              props.formMode.kind === 'update' || props.formMode.kind === 'cpc-update'
                ? props.formMode.initContent
                : undefined
            }
            contentVideoSpecs={
              props.formMode.kind === 'update' || props.formMode.kind === 'cpc-update'
                ? props.formMode.contentVideoSpecs
                : undefined
            }
            topTextBoxContentChanged={setTopBoxContent}
            setBoxesOnMajorChange={setBoxes}
          />
          <div className="tw-mb-8" />
          {props.formMode.kind === 'new' || props.formMode.kind === 'update' ? (
            <>
              <div className="tw-mt-4 tw-mb-2 tw-flex">
                <Modal.Heading4 className="tw-mb-0">Featured Image</Modal.Heading4>
                <Badge className="tw-ml-2 tw-h-1/2" variant="info">
                  Optional
                </Badge>
              </div>
              <p>The featured image appears in the feed but is not included within the post itself.</p>
              <FeaturedImageBox
                ref={featuredImageBoxRef}
                uploadedCallback={uploadId => setFeaturedImageUploadId(uploadId)}
                removedInitialCallback={() => setInitialFeaturedImageRemoved(true)}
                initialImage={props.formMode.kind === 'update' ? props.formMode.featuredImage : undefined}
                presetFeaturedImages={presetFeaturedImages}
              />
            </>
          ) : null}
          {props.formMode.kind === 'cpc-update' ? (
            <Alert variant="warn">
              <Alert.Heading>Fidelity to Original</Alert.Heading>
              Native experiences must be faithful to the original work. Failure to do so can result in revocation of
              your author privileges.
            </Alert>
          ) : null}
          <Button
            block
            className="tw-mb-4 tw-mt-8"
            type="submit"
            disabled={
              (topBoxContent.length === 0 && boxes.length <= 1) ||
              (titleRequired !== 'no' && (title.length === 0 || !isTitlePlaintext))
            }
          >
            <div className="tw-flex tw-items-center tw-justify-center">
              <span className="tw-mr-2">{props.formMode.kind === 'new' ? 'Post' : 'Update Post'}</span>
              <Spinner.Presence>{inFlight ? <Spinner color="light" /> : null}</Spinner.Presence>
            </div>
          </Button>
        </form>
      </Modal.Body>
    </Modal.Container>
  );
};

interface FeaturedImageBoxMethods {
  getUploaderStatus: () => UploaderStatus;
}

interface FeaturedImageBoxProps {
  uploadedCallback: (uploadId: string, tempMediaUrl?: string) => void;
  removedInitialCallback: () => void;
  initialImage?: api.feed.IImage;
  presetFeaturedImages: PresetFeaturedImage[];
}

interface PresetFeaturedImage {
  uploadId: string;
  tempMediaUrl: string;
}

const FeaturedImageBox = React.forwardRef<FeaturedImageBoxMethods, FeaturedImageBoxProps>((props, ref) => {
  const { uploadMedia, uploaderStatus } = useMediaUploader(
    'image',
    { '.tag': 'post_cover_image' },
    props.uploadedCallback,
  );
  const [imgFile, setImgFile] = React.useState<[File, string] | null>(null);
  const [hideInitialImage, setHideInitialImage] = React.useState(false);
  const [chosenPresetImage, setChosenPresetImage] = React.useState<PresetFeaturedImage | null>(null);

  React.useImperativeHandle(ref, () => ({
    getUploaderStatus: () => {
      if (chosenPresetImage) {
        return {
          kind: 'done',
          tempMediaUrl: chosenPresetImage.tempMediaUrl,
          uploadId: chosenPresetImage.uploadId,
        };
      } else {
        return uploaderStatus;
      }
    },
  }));

  return (
    <div className="tw-flex tw-flex-col tw-p-4 tw-bg-highlight tw-rounded-md">
      {imgFile || chosenPresetImage ? (
        <>
          <div
            role="button"
            className="tw-self-end tw-mb-2"
            onClick={() => {
              setImgFile(null);
              setChosenPresetImage(null);
            }}
          >
            <CloseCircleIcon size="1.5rem" className="tw-text-muted" />
          </div>
          {imgFile ? (
            <div className="tw-relative">
              <img
                src={uploaderStatus.kind === 'done' ? uploaderStatus.tempMediaUrl : null ?? imgFile[1]}
                className={clsx('tw-object-cover', uploaderStatus.kind !== 'done' ? 'tw-opacity-30' : null)}
              />
              {uploaderStatus.kind === 'uploading' ? (
                <div className="tw-absolute tw-top-1 tw-left-1">
                  <Badge variant="info">{uploaderStatus.progress}%</Badge>
                </div>
              ) : uploaderStatus.kind === 'done' ? (
                <div className="tw-absolute tw-top-1 tw-left-1">
                  <Badge variant="success">ready</Badge>
                </div>
              ) : null}
            </div>
          ) : chosenPresetImage ? (
            <img src={chosenPresetImage.tempMediaUrl} className="tw-object-cover" />
          ) : null}
        </>
      ) : props.initialImage && !hideInitialImage ? (
        <>
          <div
            role="button"
            className="tw-self-end tw-mb-2"
            onClick={() => {
              setHideInitialImage(true);
              props.removedInitialCallback();
            }}
          >
            <CloseCircleIcon size="1.5rem" className="tw-text-muted" />
          </div>
          <div className="tw-relative">
            <img src={props.initialImage.url} />
          </div>
        </>
      ) : (
        <div>
          <FilePicker
            onDrop={file => {
              setChosenPresetImage(null);
              setImgFile([file, URL.createObjectURL(file)]);
              uploadMedia(file);
            }}
            buttonLabel="Choose Image"
            inputAccept=".jpg,.jpeg,.png,.avif,.webp"
            dropAcceptExts={['.jpg', '.jpeg', '.png', '.avif', '.webp']}
            containerClassName="tw-py-8"
            maxFileSize={[10_000_000, '10MB']}
          />
          {props.presetFeaturedImages.length > 0 ? (
            <div className="tw-mt-4">
              <Modal.Heading4>Choose from post</Modal.Heading4>
              <div className="tw-grid tw-grid-cols-3 tw-gap-4">
                {props.presetFeaturedImages.map(presetFeaturedImage => {
                  return (
                    <div key={presetFeaturedImage.uploadId}>
                      <div
                        role="button"
                        onClick={() => {
                          setChosenPresetImage(presetFeaturedImage);
                          props.uploadedCallback(presetFeaturedImage.uploadId, presetFeaturedImage.tempMediaUrl);
                        }}
                      >
                        <img
                          src={presetFeaturedImage.tempMediaUrl}
                          className="tw-rounded tw-object-cover tw-max-h-[12rem]"
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
});

export const EditPostModal = (props: {
  feedId: string;
  entry: api.feed.IFeedEntryReference;
  close: () => void;
  isForeground: boolean;
  isCpc: boolean;
  autoGenFromSource?: boolean;
}) => {
  const apiClient = useAuthedApiClient();
  const [cpc, setCpc] = useState<[string | undefined, string] | null>(null);
  const [contentVideoSpecs, setContentVideoSpecs] = useState<api.feed.IContentVideoSpec[] | null>(null);
  const primaryUrl = cfe.ApiHelpers.getEntryPrimaryUrl(props.entry);
  const md = cfe.ApiHelpers.getEntryMetadata(props.entry);

  const { apiDo: apiFeedEntryContentGet } = useApiDo(apiClient, apiClient.feedEntryContentGet, { abortable: true });
  const { apiDo: apiStaffOpAutoCpcForUrl, errToast } = useApiDo(apiClient, apiClient.staffOpAutoCpcForUrl);

  useKeyPress(
    ['u', 'Escape'],
    () => {
      props.close();
    },
    !props.isForeground,
    10,
  );

  useEffect(() => {
    if (md['.tag'] === 'ready' && md.content_type['.tag'] === 'post' && md.post && md.post.content.length > 0) {
      // Handle short posts
      setCpc([undefined, md.post.content]);
      return;
    }
    // Can be long posts or entries w/ failed metadata extractions.
    const knownTitle = md['.tag'] === 'ready' ? md.title : undefined;
    if (props.autoGenFromSource) {
      apiStaffOpAutoCpcForUrl(
        { url: primaryUrl },
        {
          // If cpc cannot be automatically generated, force the modal closed
          // so that the user isn't stuck with a blank modal.
          onAnyErr: () => props.close(),
          onResult: res => {
            setCpc([knownTitle, res.cpc]);
          },
          onRouteErr: err => {
            if (err['.tag'] === 'fetch_fail') {
              errToast('Could not fetch stimulus');
            } else if (err['.tag'] === 'extract_fail') {
              errToast('Could not extract article');
            } else if (err['.tag'] === 'unsupported_content_type') {
              errToast('Unsupported content type');
            } else {
              errToast(err['.tag']);
            }
          },
        },
      );
    } else {
      apiFeedEntryContentGet(
        { entry_id: props.entry.entry_id },
        {
          onResult: res => {
            setCpc([knownTitle, res.content]);
            setContentVideoSpecs(res.video_specs);
          },
          onRouteErr: (err, defaultErrToast) => {
            if (err['.tag'] === 'no_content') {
              if (!props.isCpc) {
                throw Error('Unexpected: Non-cpc with no content.');
              }
              setCpc([knownTitle, '']);
            } else {
              defaultErrToast();
            }
          },
        },
      );
    }
  }, []);

  if (!cpc) {
    return null;
  }

  return (
    <PostInnerModal
      feedId={props.feedId}
      closeModal={props.close}
      isForeground={props.isForeground}
      formMode={{
        contentVideoSpecs: contentVideoSpecs ?? undefined,
        entryId: props.entry.entry_id,
        featuredImage: md['.tag'] === 'ready' ? md.image : undefined,
        initContent: cpc[1],
        initTitle: cpc[0],
        kind: props.isCpc ? 'cpc-update' : 'update',
      }}
    />
  );
};

export default PostModal;
