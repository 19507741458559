import clsx from 'clsx';
import * as api from 'ego-sdk-js';
import { m } from 'framer-motion';
import React from 'react';
import { thumbHashToDataURL } from 'thumbhash';

import * as config from '../config';
import { base64ToByteArray } from '../util';

import ExpandIcon from './icon/ExpandIcon';
import { ImageViewerModal } from './ImageWithViewer';
import Button from './lib/Button';
import { useModalManager } from './ModalManagerContext';

const FeedEntryImage = (props: {
  md: api.feed.MetadataStateReady;
  className?: string;
  customHeightClassName?: string;
}) => {
  const [loaded, setLoaded] = React.useState(false);
  const [thumbhashDataUrl, setThumbhashDataUrl] = React.useState<string | null>(null);
  React.useEffect(() => {
    if (props.md.image && props.md.image.th) {
      setThumbhashDataUrl(thumbHashToDataURL(base64ToByteArray(props.md.image.th)));
    }
  }, [props.md]);

  const { pushModal } = useModalManager();

  if (!props.md.image) {
    return null;
  }
  const portraitVideoImage =
    !!props.md.image &&
    props.md.image.height > props.md.image.width &&
    props.md.content_type['.tag'] === 'video' &&
    !!props.md.content_type.portrait;
  return (
    <div
      className={clsx('tw-relative tw-group', props.className)}
      onMouseOver={() => {
        if (!props.md.image) {
          return;
        }
        // Take the mouseover as a signal to prefetch the image.
        const img = new Image();
        img.src = props.md.image.url;
      }}
    >
      {thumbhashDataUrl ? (
        <div className="tw-absolute tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center">
          <img className={clsx('tw-w-full', props.customHeightClassName ?? 'tw-h-full')} src={thumbhashDataUrl} />
        </div>
      ) : null}
      <m.picture
        className="tw-relative tw-z-10 tw-flex tw-justify-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: loaded ? 1 : 0 }}
        transition={{ duration: 0.2 }}
      >
        {props.md.thumbnail ? <source srcSet={props.md.thumbnail.url} media={config.mediaBreakpoints.lgMin} /> : null}
        <img
          width={props.md.image.width}
          height={props.md.image.height}
          src={props.md.image.url}
          loading="lazy"
          onLoad={() => setLoaded(true)}
          className={clsx(
            'tw-object-contain tw-rounded-[1px]',
            !props.customHeightClassName ? 'tw-max-h-[16rem]' : null,
            !props.customHeightClassName && !portraitVideoImage ? 'lg:tw-max-h-[12rem]' : null,
            props.customHeightClassName,
          )}
        />
      </m.picture>
      <div className="tw-absolute tw-bottom-2 tw-right-6 tw-z-10 tw-invisible group-hover:tw-visible">
        <Button
          variant="secondary"
          onClick={e => {
            e.stopPropagation();
            pushModal({
              component: modalProps => <ImageViewerModal {...modalProps} image={props.md.image!} />,
              dupKey: 'cpc-editor',
              kind: 'generic',
            });
          }}
        >
          <ExpandIcon size="1rem" />
        </Button>
      </div>
    </div>
  );
};

export default FeedEntryImage;
