import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import { AnimatePresence, m } from 'framer-motion';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { MainActionCreators } from '../state/reducer';
import { addUrlScheme } from '../util';

import { QuotedBlabber } from './FeedEntryMatter';
import { useAuthedApiClient } from './hooks/useApiClient';
import useApiDo from './hooks/useApiDo';
import useFeedEntryRefresher from './hooks/useFeedEntryRefresher';
import useUserMeInternal, { useUserMeInternalRefreshed } from './hooks/useUserMeInternal';
import CircleDownIcon from './icon/CircleDown';
import { useKeyPress } from './KeyPressContext';
import Alert from './lib/Alert';
import Button, { ButtonLinkExternal } from './lib/Button';
import Collapse from './lib/Collapse';
import InputLabel from './lib/InputLabel';
import LayoutLine from './lib/LayoutLine';
import Modal from './lib/Modal';
import Spinner from './lib/Spinner';
import TextAreaInput from './lib/TextAreaInput';
import TextInput, { TextInputFooterForOptional } from './lib/TextInput';
import ToggleForm from './lib/ToggleForm';

const PieChart = React.lazy(() => import('./lib/PieChart'));

const GiveEgoModal = (props: {
  src: [string, api.feed.IFeedEntryReference] | null;
  closeModal: () => void;
  isForeground: boolean;
}) => {
  const accountInfo = useUserMeInternal();
  if (accountInfo) {
    return <GiveEgoModalLoggedIn {...props} />;
  } else {
    return <GiveEgoModalLoggedOut {...props} />;
  }
};

const GiveEgoModalLoggedIn = (props: {
  src: [string, api.feed.IFeedEntryReference] | null;
  closeModal: () => void;
  isForeground: boolean;
}) => {
  const dispatch = useDispatch();
  const apiClient = useAuthedApiClient();
  const { refreshFeedEntry } = useFeedEntryRefresher(apiClient);
  const [srcFeedId, srcEntry] = props.src ?? [null, null];
  const title = srcEntry ? cfe.ApiHelpers.getEntryTitle(srcEntry) : null;
  const quotableSrcEntry = srcEntry !== null && (srcEntry['.tag'] === 'ego' || srcEntry['.tag'] === 'post');

  const initUrlIsEgoUrl = srcEntry && srcEntry.url.startsWith('ego://');

  const [inFlight, setInFlight] = useState(false);
  const [noAgree, setNoAgree] = useState(false);

  const [url, setUrl] = useState(srcEntry ? srcEntry.strong_ref?.url ?? srcEntry.url : '');
  const [quoteSource, setQuoteSource] = useState(quotableSrcEntry && srcEntry.blabber !== undefined);

  useKeyPress(
    ['u', 'Escape', '1'],
    () => {
      props.closeModal();
    },
    !props.isForeground,
    10,
  );

  const accountInfo = useUserMeInternalRefreshed();

  const { apiDo: apiFeedEntryAddEgo, okToast, errToast } = useApiDo(apiClient, apiClient.feedEntryAddEgo);

  const { result: billingTpStmt } = cfe.ApiHook.useApiRead(
    apiClient,
    apiClient.billingTippingPeriodStatementCurrent,
    undefined,
    res => res,
    !accountInfo.is_subscriber,
  );

  const [review, setReview] = useState('');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setInFlight(true);
    const nullableReview = review.length > 0 ? review : undefined;
    const fullyQualifiedUrl = addUrlScheme(url);
    const viaSet: api.feed.ISetVia | undefined = srcEntry?.entry_id
      ? {
          entry_id: srcEntry.entry_id,
          quote_source: quoteSource,
        }
      : undefined;

    apiFeedEntryAddEgo(
      {
        blabber: nullableReview,
        feed_id: 'me/ego',
        no_agree: noAgree,
        set_via: viaSet,
        url: fullyQualifiedUrl,
      },
      {
        onFinally: () => setInFlight(false),
        onResult: res => {
          if (res['.tag'] === 'existing') {
            errToast('You egoed this recently.');
          } else {
            if (res['.tag'] === 'new_entry') {
              // This no-ops silently if the ego feed isn't in our redux store.
              dispatch(MainActionCreators.prependFeedEntry(accountInfo.ego_feed_id, res.new_entry));
              if (res.new_entry.feed_ref_counts && srcEntry) {
                dispatch(
                  MainActionCreators.setFeedEntryEgoCount(
                    srcFeedId ?? 'newsfeed',
                    srcEntry.entry_id,
                    res.new_entry.feed_ref_counts.ego,
                  ),
                );
              }
              // FIXME: It's unnecessary to refresh this entry unless it's
              // currently shown (ie. user is viewing ego feed)
              refreshFeedEntry(accountInfo.ego_feed_id, res.new_entry, 5, entry => entry.md['.tag'] !== 'not_ready');
            }
            okToast('Egoed!');
            props.closeModal();
          }
        },
        onRouteErr: (err, defaultErrToast) => {
          if (err['.tag'] === 'bad_url') {
            errToast('Not a valid link.');
          } else if (err['.tag'] === 'bad_blabber') {
            errToast('Your note uses disallowed markup.');
          } else if (err['.tag'] === 'not_subscriber') {
            errToast('You must be a subscriber to ego.');
          } else if (err['.tag'] === 'ego_limit_reached') {
            errToast('You shared all of your ego for this period.');
          } else if (err['.tag'] === 'slow_down') {
            errToast('Slow down', `Try again in ${cfe.Formatter.secondsToHoursMinsSecsStr(err.wait_period)}`);
          } else {
            defaultErrToast();
          }
        },
      },
    );
  };

  const colorPalette = [
    '#800080',
    '#68499E',
    '#662D91',
    '#2B70A7',
    '#BF1E2E',
    '#EF4136',
    '#F15A2B',
    '#E2B37D',
    '#2A3890',
    '#28AAE1',
    '#77B3E1',
    '#B5D4EF',
    '#006838',
    '#009445',
    '#39B54A',
    '#8DC73F',
    '#D7E022',
    '#F9ED32',
    '#F5F194',
    '#F20B97',
    '#F453AD',
  ];

  const pieData = [];
  for (let i = 0; i < (cfe.ApiData.hasData(billingTpStmt) ? billingTpStmt.data.tips_given + 1 : 1); i++) {
    pieData.push({
      color: colorPalette[i % colorPalette.length],
      value: 1,
    });
  }

  let daysToPeriodEnd: number | null = null;
  if (cfe.ApiData.hasData(billingTpStmt)) {
    const end = Date.parse(billingTpStmt.data.end_date);
    const now = Date.now();
    daysToPeriodEnd = Math.ceil(Math.abs(end - now) / (1000 * 60 * 60 * 24));
  }

  const [showRules, setShowRules] = React.useState(false);

  return (
    <Modal.Container show={props.isForeground} close={props.closeModal}>
      <Modal.Header className="tw-flex-col">
        <Modal.Heading1>Give Ego</Modal.Heading1>
        {title ? <div className="tw-text-muted">{title}</div> : null}
      </Modal.Header>
      <Modal.Body gutter>
        <div className="tw-cursor-pointer" role="button" onClick={() => setShowRules(!showRules)}>
          <Alert variant="info" className="tw-mt-4">
            <Modal.Heading2 className="!tw-mt-2">
              <div className="tw-flex tw-justify-between">
                <span>Rules</span>
                <m.div initial={{ rotateZ: 0 }} animate={{ rotateZ: showRules ? 180 : 0 }}>
                  <CircleDownIcon size="1.5rem" />
                </m.div>
              </div>
            </Modal.Heading2>
            <Collapse open={showRules}>
              <div className="tw-mt-2" />
              <p>You can ego anything, but with great power comes great responsibility.</p>
              <span className="tw-font-bold">No exchange</span>
              <p>Ego must not be given in exchange for anything from the author.</p>
              <span className="tw-font-bold">No interest</span>
              <p>
                Ego must not be given to anyone you have a special interest in (e.g. friends, family, colleagues, …).
              </p>
              <span className="tw-font-bold">No promotion</span>
              <p>Do not ego anything for the purposes of spreading the word.</p>
            </Collapse>
          </Alert>
        </div>
        <form onSubmit={handleSubmit}>
          <fieldset disabled={inFlight}>
            <div className="tw-mt-6" />
            {!initUrlIsEgoUrl && !title ? (
              <div className="tw-mb-6">
                <InputLabel className="font-weight-bold">Link</InputLabel>
                <TextInput
                  type="text"
                  placeholder="www.youtube.com/watch?v=dQw4w9WgXcQ"
                  onChange={(e: React.FormEvent<any>) => setUrl(e.currentTarget.value)}
                  value={url}
                  required
                  disabled={srcEntry !== null}
                />
              </div>
            ) : null}
            <div className="tw-mb-6">
              <InputLabel>Add a note for the author and your followers</InputLabel>
              <TextAreaInput
                className="tw-mt-2"
                autoHeight={300}
                placeholder="Say something about this that makes the world a better place..."
                onChange={e => setReview(e.currentTarget.value)}
                value={review}
                maxLength={500}
              />
              <TextInputFooterForOptional value={review} maxLength={500} />
            </div>
            {quotableSrcEntry && srcEntry.blabber ? (
              <div className="tw-mb-6">
                <ToggleForm
                  label={`Quote ${srcEntry.added_by!.name}`}
                  blurb={`Include what ${srcEntry.added_by!.name} wrote in your ego`}
                  on={quoteSource}
                  onToggle={() => setQuoteSource(!quoteSource)}
                />
                <QuotedBlabber blabber={srcEntry.blabber} navToFeed={() => null} />
              </div>
            ) : null}
            <div className="tw-mb-6">
              <ToggleForm
                label="Ego without agreeing"
                blurb="Label that it's ego-worthy, but you don't necessarily agree with it."
                on={noAgree}
                onToggle={() => setNoAgree(!noAgree)}
              />
            </div>
            <Button block type="submit" className="tw-mt-2">
              Give Ego
            </Button>
          </fieldset>
        </form>
        <LayoutLine className="!tw-my-10" />
        <div className="tw-flex tw-flex-col tw-bg-highlight tw-px-6 tw-pt-2 tw-pb-6 tw-my-6 tw-rounded">
          <Modal.Heading2>What's your ego worth?</Modal.Heading2>
          <div className="tw-flex tw-flex-col tw-items-center tw-mt-6">
            <React.Suspense fallback={<Spinner lg />}>
              <AnimatePresence mode="popLayout" initial={false}>
                {cfe.ApiData.hasData(billingTpStmt) && daysToPeriodEnd !== null && pieData.length > 0 ? (
                  <m.div
                    key="subscriber"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.4 }}
                  >
                    <div className="tw-max-w-[16rem] tw-mx-auto">
                      <PieChart paddingAngle={1} data={pieData} />
                    </div>
                    <div className="tw-mt-6 tw-grid tw-grid-cols-5 tw-gap-y-2 tw-gap-x-4 tw-max-w-md tw-w-[90%]">
                      <InfoRow text="Your total contribution to authors every two-week period.">
                        <span className="tw-text-base">$</span>
                        {(billingTpStmt.data.amount / 100).toFixed(0)}
                      </InfoRow>
                      <InfoRow text="Egos given this period including this one.">
                        {billingTpStmt.data.tips_given + 1}
                      </InfoRow>
                      <InfoRow text="Days left in two-week period.">{daysToPeriodEnd}</InfoRow>
                    </div>
                  </m.div>
                ) : cfe.ApiData.isLoading(billingTpStmt) && accountInfo.is_subscriber ? (
                  <m.div
                    key="loading"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.4 }}
                    className="tw-max-w-[16rem]"
                  >
                    <PieChart
                      paddingAngle={1}
                      data={[
                        { value: 1, color: '#aaa' },
                        { value: 1, color: '#bbb' },
                        { value: 1, color: '#ccc' },
                      ]}
                    />
                  </m.div>
                ) : !accountInfo.is_subscriber ? (
                  <>
                    <m.div
                      key="nonsubscriber"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.4 }}
                      className="tw-max-w-[16rem]"
                    >
                      <PieChart paddingAngle={1} data={pieData} />
                    </m.div>
                    <div className="tw-mt-6 tw-grid tw-grid-cols-5 tw-gap-y-2 tw-gap-x-4 tw-max-w-md tw-w-[90%]">
                      <InfoRow text="Your total contribution to authors every two-week period. Your ego isn't worth $$$ to authors until you join & subscribe.">
                        <span className="tw-text-base">$</span>0
                      </InfoRow>
                    </div>
                  </>
                ) : null}
              </AnimatePresence>
            </React.Suspense>
          </div>
        </div>
      </Modal.Body>
    </Modal.Container>
  );
};

const InfoRow = (props: { children: React.ReactNode; text: string }) => (
  <>
    <div className="tw-col-span-1 tw-font-bold tw-text-4xl tw-flex tw-items-center tw-justify-end">
      {props.children}
    </div>
    <div className="tw-col-span-4 tw-flex tw-items-center">{props.text}</div>
  </>
);

const GiveEgoModalLoggedOut = (props: {
  src: [string, api.feed.IFeedEntryReference] | null;
  closeModal: () => void;
  isForeground: boolean;
}) => {
  const [, srcEntry] = props.src ?? [null, null];
  const title = srcEntry ? cfe.ApiHelpers.getEntryTitle(srcEntry) : null;
  useKeyPress(
    '1',
    () => {
      props.closeModal();
    },
    !props.isForeground,
    10,
  );
  useKeyPress(
    'u',
    () => {
      props.closeModal();
    },
    !props.isForeground,
    10,
  );

  return (
    <Modal.Container show={props.isForeground} close={props.closeModal}>
      <Modal.Header className="tw-flex-col">
        <Modal.Heading1>Give Ego</Modal.Heading1>
        {title ? <div className="tw-text-muted">{title}</div> : null}
      </Modal.Header>
      <Modal.Body gutter>
        <Alert variant="info" className="tw-mt-4">
          <Modal.Heading2 className="!tw-mt-2 tw-text-center">Account Required</Modal.Heading2>
          <div className="tw-mt-4" />
          <div className="tw-flex tw-flex-col tw-gap-4 tw-mb-4">
            <div className="tw-flex tw-justify-center">
              <ButtonLinkExternal href="/register" className="!tw-w-full md:!tw-w-60">
                Join the Movement
              </ButtonLinkExternal>
            </div>
            <div className="tw-flex tw-justify-center">
              <ButtonLinkExternal href="/login" className="!tw-w-full md:!tw-w-60" variant="secondary">
                Sign In
              </ButtonLinkExternal>
            </div>
          </div>
        </Alert>

        <div className="tw-flex tw-flex-col tw-bg-highlight tw-px-6 tw-pt-2 tw-pb-6 tw-my-6 tw-rounded">
          <Modal.Heading2>What's your ego worth?</Modal.Heading2>
          <div className="tw-flex tw-flex-col tw-items-center tw-mt-6">
            <React.Suspense fallback={<Spinner lg />}>
              <AnimatePresence mode="popLayout" initial={false}>
                <m.div
                  key="nonsubscriber"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.4 }}
                  className="tw-max-w-[16rem]"
                >
                  <PieChart
                    paddingAngle={1}
                    data={[
                      { value: 1, color: '#aaa' },
                      { value: 1, color: '#bbb' },
                      { value: 1, color: '#ccc' },
                    ]}
                  />
                </m.div>
                <div className="tw-mt-6 tw-grid tw-grid-cols-5 tw-gap-y-2 tw-gap-x-4 tw-max-w-md tw-w-[90%]">
                  <InfoRow text="Your total contribution to authors every two-week period. Your ego isn't worth $$$ to authors until you join & subscribe.">
                    <span className="tw-text-base">$</span>0
                  </InfoRow>
                </div>
              </AnimatePresence>
            </React.Suspense>
          </div>
        </div>
      </Modal.Body>
    </Modal.Container>
  );
};

export default GiveEgoModal;
