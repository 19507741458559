import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React from 'react';

import { CpcSliderContext } from './FeedEntryCpcViewer';
import Spinner from './lib/Spinner';
import type { VideoSource } from './VideoPlayer';

const VideoPlayer = React.lazy(() => import('./VideoPlayer'));

interface VideoEmbedProps {
  sources: VideoSource[];
  portrait: boolean;
  noOverflow: boolean;
  forceNoAutoPlay?: boolean;
  gutterClassName?: string;
  maxHeightShort?: boolean;
  parentSlideIndex?: number;
  // Necessary, but not sufficient, for agent API options.
  stimUrl?: string;
  mainEntry?: api.feed.IFeedEntryPair;
}

const VideoEmbed = React.memo(
  (props: VideoEmbedProps) => {
    const cpcSliderContext = React.useContext(CpcSliderContext);
    return (
      <React.Suspense fallback={<Spinner lg className="tw-self-center" />}>
        <VideoPlayer
          sources={props.sources}
          portrait={props.portrait}
          noOverflow={props.noOverflow}
          forceNoAutoPlay
          focusPlay={cpcSliderContext.slideIndex === props.parentSlideIndex}
          gutterClassName={props.gutterClassName}
          onEnd={cpcSliderContext.nextSlide}
          maxHeightShort={props.maxHeightShort}
          containerClassName={props.portrait ? 'tw-grow' : undefined}
          explicitPortraitVideoHeight={props.parentSlideIndex === undefined}
          stopPropagation
          stimUrl={props.stimUrl}
          mainEntry={props.mainEntry}
        />
      </React.Suspense>
    );
  },
  (prevProps, nextProps) =>
    prevProps.sources === nextProps.sources &&
    prevProps.portrait === nextProps.portrait &&
    prevProps.noOverflow === nextProps.noOverflow &&
    prevProps.forceNoAutoPlay === nextProps.forceNoAutoPlay &&
    prevProps.gutterClassName === nextProps.gutterClassName &&
    prevProps.maxHeightShort === nextProps.maxHeightShort &&
    prevProps.parentSlideIndex === nextProps.parentSlideIndex &&
    prevProps.stimUrl === nextProps.stimUrl &&
    prevProps.mainEntry === nextProps.mainEntry,
);

export const VideoEmbedFromSpec = (
  props: { videoSpec: api.feed.IContentVideoSpec } & Omit<VideoEmbedProps, 'sources' | 'portrait'>,
) => {
  const { videoSpec, ...rest } = props;
  return (
    <VideoEmbed
      {...rest}
      sources={((): VideoSource[] => {
        const sources: VideoSource[] = [];
        // NOTE: video.js uses a built-in http-streaming library to
        // support dash. However, it does not support multi-segment webm.
        // If required, can later switch to videojs-contrib-dash.
        if (videoSpec.hls) {
          sources.push({
            src: videoSpec.hls!,
            type: 'application/x-mpegURL',
          });
        }
        return sources;
      })()}
      portrait={videoSpec.portrait ?? false}
      stimUrl={videoSpec.url}
      mainEntry={videoSpec.main_entry}
    />
  );
};

export const VideoEmbedFromUrl = (
  props: { apiClient: api.SuperegoClient; url: string } & Omit<VideoEmbedProps, 'sources' | 'portrait'>,
) => {
  const { apiClient, url, ...rest } = props;
  const { result: stimulusGetAppVideoResult } = cfe.ApiHook.useApiRead(
    apiClient,
    apiClient.stimulusGetAppVideo,
    { url },
    res => res,
  );
  if (!cfe.ApiData.hasData(stimulusGetAppVideoResult)) {
    return null;
  }
  const videoMd = stimulusGetAppVideoResult.data.video_md;

  return (
    <VideoEmbed
      {...rest}
      sources={((): VideoSource[] => {
        const sources: VideoSource[] = [];
        // NOTE: video.js uses a built-in http-streaming library to
        // support dash. However, it does not support multi-segment webm.
        // If required, can later switch to videojs-contrib-dash.
        if (videoMd.hls) {
          sources.push({
            src: videoMd.hls!,
            type: 'application/x-mpegURL',
          });
        }
        return sources;
      })()}
      portrait={videoMd.portrait ?? false}
      stimUrl={props.url}
    />
  );
};

export default VideoEmbed;
