import clsx from 'clsx';
import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React from 'react';
import { scrollTo } from 'scroll-js';

import FeedEntryActions, { FeedEntryCompactActions } from './FeedEntryActions';
import FeedEntryImage from './FeedEntryImage';
import FeedEntryLeftSwipeTriangles from './FeedEntryLeftSwipeTrianges';
import {
  AgentLine,
  DiscussionPreviewMatter,
  QnaPreviewMatter,
  SourceTracePreviewMatter,
  StoryLowerMatter,
  StoryUnderMatter,
  StoryUpperMatter,
} from './FeedEntryMatter';
import FeedEntryReply from './FeedEntryReply';
import useFeedEntryOps, { useFeedEntryFeedbackOps } from './hooks/useFeedEntryOps';
import useNav from './hooks/useNav';
import useSeenRecorder from './hooks/useSeenRecorder';
import { AccountInfoMaybe } from './hooks/useUserMeInternal';
import HoverTouchWheelMenu from './HoverTouchWheelMenu';
import { useKeyPress } from './KeyPressContext';
import Badge from './lib/Badge';
import ThreadFfa from './ThreadFfa';

// WARN: There's a memoized version below that needs to have its
// prop-comparison fn updated whenever a new prop is added here!
const FeedEntryListItemBase = (props: {
  accountInfo: AccountInfoMaybe;
  entry: api.feed.IFeedEntryReference;
  feed: api.feed.IFeedInfo;
  selected: boolean;
  primaryKbOnly: boolean;
  inNewsfeed: boolean;
  compact: boolean;
  bgColor?: string;
  takeKbCursor: () => void;
  goToFeed: (feed: api.feed.IFeedInfo) => void;
  apiClient: api.SuperegoClient;
  agentMode: boolean;
  topScore?: number;
  rewind?: boolean;
  hideBlurb?: boolean;
  hideImage?: boolean;
  newsfeedInclusionReason?: api.newsfeed.InclusionReason;
}) => {
  const { navToFeed } = useNav();
  const seenRecorder = useSeenRecorder();
  const feedEntryOps = useFeedEntryOps(props.apiClient, props.accountInfo, props.feed, props.entry, props.agentMode);
  const feedbackOps = useFeedEntryFeedbackOps(props.apiClient, props.feed, props.entry, props.newsfeedInclusionReason);
  const md = cfe.ApiHelpers.getEntryMetadata(props.entry);

  const [kbSection, setKbSection] = React.useState<'story' | 'ffa-thread'>('story');

  useKeyPress(
    'O',
    () => {
      // shift + o: Open discussion preview if available.
      if (feedEntryOps.openDiscussionPreview) {
        feedEntryOps.openDiscussionPreview();
      }
    },
    !props.selected || kbSection === 'ffa-thread',
  );
  useKeyPress(
    'o',
    () => {
      // o: Open primary url in a new tab.
      feedEntryOps.open();
    },
    !props.selected || kbSection === 'ffa-thread',
  );
  useKeyPress(
    '1',
    () => {
      // 1: Give-ego modal
      if (feedEntryOps.ego) {
        feedEntryOps.ego();
      }
    },
    !props.selected || kbSection === 'ffa-thread',
  );
  useKeyPress(
    '2',
    (_, e) => {
      // Prevent '/' from immediately being input into search box.
      e.preventDefault();
      // 2: Send-to modal
      if (feedEntryOps.sendTo) {
        feedEntryOps.sendTo();
      }
    },
    !props.selected || kbSection === 'ffa-thread',
  );
  useKeyPress(
    '3',
    () => {
      // 3: Save for later
      if (!props.entry.for_viewer.s4l && feedEntryOps.saveForLater) {
        feedEntryOps.saveForLater();
      } else if (feedEntryOps.saveToLibrary) {
        feedEntryOps.saveToLibrary();
      }
    },
    !props.selected || kbSection === 'ffa-thread',
  );

  const divRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (!props.selected || !divRef.current) {
      return;
    }
    const scrollYTarget = divRef.current.getBoundingClientRect().top + window.scrollY - window.innerHeight / 2 + 150;
    scrollTo(window, { top: scrollYTarget, easing: 'ease-in', duration: 100 });
    // Scroll to the currently selected blob if compact mode changes since the
    // blob will likely not be in the viewport any longer.
  }, [divRef.current, props.selected, props.compact]);

  const entryHasFfaThread = props.entry['.tag'] === 'share';
  const markSeenTimeoutId = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  const [loadShareFfaThread, setLoadShareFfaThread] = React.useState(false);
  const footerRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    // NOTE: It's likely more performant to have a single interaction observer
    // for the entire page rather than an independent one for each entry, but
    // this is cleaner architecturally.
    const intersectionObserver = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && !markSeenTimeoutId.current && !loadShareFfaThread) {
          markSeenTimeoutId.current = setTimeout(() => {
            if (entryHasFfaThread) {
              // NOTE: Load FFA thread lazily when item is visible. Otherwise,
              // we'll make O(n) queries to the API for all the FFA threads
              // rather than O(1) for the comparably few FFA threads visible on
              // initial load.
              setLoadShareFfaThread(true);
            }
            seenRecorder([[props.feed, props.entry]]);
            markSeenTimeoutId.current = null;
            // NOTE: The components initially render without images (of course,
            // they're loaded async) which makes a lot more entry items visible
            // than normally would when all images are loaded. This delay +
            // cancellation logic helps reduce the false-positive visibility
            // rate.
          }, 100);
        } else if (!entries[0].isIntersecting && markSeenTimeoutId.current) {
          clearTimeout(markSeenTimeoutId.current);
          markSeenTimeoutId.current = null;
        }
      },
      // TODO: In the UI, it's obvious when an item comes into view and the FFA
      // Thread is lazily loaded. An improvement would be to have a smaller
      // threshold for triggering FFA load, but maintain the same threshold for
      // marking-as-seen.
      { threshold: 0.75 },
    );
    if (!footerRef.current) {
      return;
    }
    intersectionObserver.observe(footerRef.current);
    return () => {
      if (!footerRef.current) {
        return;
      }
      intersectionObserver.unobserve(footerRef.current);
    };
  }, [footerRef.current]);

  // Cache value of unseen because when the entry comes into view it will be
  // marked as seen in the global state, but we'd like to continue showing it
  // as unseen in the UI. If the user has seen the entry but hasn't seen all
  // the thread replies, then rely on the FFA thread to highlight what's new.
  const [unseen] = React.useState(
    props.entry.for_viewer.seen !== undefined &&
      props.entry.for_viewer.seen.unseen &&
      props.entry.for_viewer.seen.seen_watermark === undefined,
  );

  const leftSwipeFeeds = React.useMemo(() => {
    const leftSwipeFeedsToOmit = new Set();
    const leftSwipeFeedsTemp = [];
    if (!props.inNewsfeed) {
      leftSwipeFeedsToOmit.add(props.feed.feed_id);
    }
    if (!leftSwipeFeedsToOmit.has(props.feed.feed_id)) {
      leftSwipeFeedsToOmit.add(props.feed.feed_id);
      leftSwipeFeedsTemp.push(props.feed);
    }
    if (props.entry.via && !leftSwipeFeedsToOmit.has(props.entry.via.feed.feed_id)) {
      leftSwipeFeedsToOmit.add(props.entry.via.feed.feed_id);
      leftSwipeFeedsTemp.push(props.entry.via.feed);
    }
    // SEP 125: Natural topic
    if (props.entry.natural_topic && !leftSwipeFeedsToOmit.has(props.entry.natural_topic.feed_id)) {
      leftSwipeFeedsToOmit.add(props.entry.natural_topic.feed_id);
      leftSwipeFeedsTemp.push(props.entry.natural_topic);
    }
    // Debut feed
    if (
      md['.tag'] === 'ready' &&
      md.hgc_info?.debut_feed &&
      !leftSwipeFeedsToOmit.has(md.hgc_info.debut_feed.feed_id)
    ) {
      leftSwipeFeedsToOmit.add(md.hgc_info.debut_feed.feed_id);
      leftSwipeFeedsTemp.push(md.hgc_info.debut_feed);
    }
    // SEP 153: Works feed
    if (props.entry.works_feed && !leftSwipeFeedsToOmit.has(props.entry.works_feed.feed_id)) {
      leftSwipeFeedsToOmit.add(props.entry.works_feed.feed_id);
      leftSwipeFeedsTemp.push(props.entry.works_feed);
    }
    // SEP 169: Channel feed
    if (
      !props.entry.works_feed &&
      md['.tag'] === 'ready' &&
      md.channel &&
      md.channel.feed &&
      md.channel.feed.feed_id !== props.feed.feed_id &&
      !leftSwipeFeedsToOmit.has(md.channel.feed.feed_id)
    ) {
      leftSwipeFeedsToOmit.add(md.channel.feed.feed_id);
      leftSwipeFeedsTemp.push(md.channel.feed);
    }
    return leftSwipeFeedsTemp;
  }, [props.inNewsfeed, props.feed, props.entry]);

  useKeyPress(
    'n',
    () => {
      if (kbSection === 'story') {
        setKbSection('ffa-thread');
      }
    },
    !props.selected || !entryHasFfaThread || !loadShareFfaThread || kbSection !== 'story',
    -10,
  );

  const kbNextSection = React.useCallback(() => null, []);
  const kbPrevSection = React.useCallback(() => setKbSection('story'), []);

  const itemClickDisabled = props.entry['.tag'] === 'notif' || props.feed.type['.tag'] === 'rec_for_you';

  //
  // Logic for mouse click long-press to "take the KB cursor."
  //
  // Useful for hybrid users of keyboard + mouse to switch back to keyboard
  // after scrolling away from the KB cursor for some time.
  //
  const holdTimeoutId = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  React.useEffect(() => {
    if (!divRef.current) {
      return;
    }
    const mouseDown = () => {
      holdTimeoutId.current = setTimeout(() => {
        props.takeKbCursor();
      }, 500);
    };
    const mouseUp = () => {
      if (holdTimeoutId.current) {
        clearTimeout(holdTimeoutId.current);
        holdTimeoutId.current = null;
      }
    };
    divRef.current.addEventListener('mousedown', mouseDown);
    divRef.current.addEventListener('mouseup', mouseUp);
    return () => {
      divRef.current?.removeEventListener('mousedown', mouseDown);
      divRef.current?.removeEventListener('mouseup', mouseUp);
    };
  }, [divRef.current]);

  const storyUpperMatterBadgePrefix = React.useMemo(
    () => (
      <>
        {unseen ? (
          <Badge sm variant="primary" className="tw-mr-1">
            New
          </Badge>
        ) : undefined}
        {props.entry.for_viewer.archived ? (
          <Badge variant="info" className="tw-mr-1">
            Archived
          </Badge>
        ) : null}
      </>
    ),
    [unseen, props.entry.for_viewer.archived],
  );

  const showImage =
    !props.hideImage &&
    !props.compact &&
    md['.tag'] === 'ready' &&
    !!md.image &&
    !(md.omit_image_nf && props.inNewsfeed);

  return (
    <div className="tw-flex tw-flex-col">
      <HoverTouchWheelMenu
        entryId={props.entry.entry_id}
        navToFeed={props.goToFeed}
        selected={props.selected}
        disableKb={!props.selected || props.primaryKbOnly}
        compact={props.compact}
        onArchive={feedEntryOps.archive}
        feedbackOps={feedbackOps}
        hideFeedbackActions={props.feed.type['.tag'] === 'notif' || props.feed.type['.tag'] === 'rec_for_you'}
        leftSwipeFeeds={leftSwipeFeeds}
      >
        <div
          ref={divRef}
          className={clsx(
            unseen ? 'tw-bg-yellow-50 dark:tw-bg-[#211f02]' : 'tw-bg-primary',
            'hover:tw-bg-highlight hover:tw-story-outline tw-pl-0 lg:tw-pl-4 kb-bar',
            !props.compact ? 'tw-shadow-sm' : null,
            'tw-rounded-lg tw-overflow-hidden',
            // Allows bottom of outline to overlap divider
            'hover:tw-z-10',
            props.selected && kbSection === 'story' ? 'kb-sel' : null,
            props.compact ? 'compact' : null,
          )}
          style={{ backgroundColor: props.bgColor }}
          onClick={e => {
            if (!itemClickDisabled) {
              feedEntryOps.open(e.ctrlKey || e.metaKey);
            }
            props.takeKbCursor();
          }}
          onAuxClick={e => {
            if (e.button === 1) {
              if (!itemClickDisabled) {
                feedEntryOps.open(true);
              }
              props.takeKbCursor();
            }
          }}
          role="button"
        >
          <div className="tw-grid tw-grid-cols-12 tw-gap-x-4 tw-gap-y-3">
            <div
              className={clsx(
                'tw-flex tw-flex-col tw-col-span-12',
                props.compact ? 'sm:tw-col-span-9' : 'lg:tw-col-span-8',
                props.compact ? 'tw-py-2 tw-gap-y-1' : 'tw-pt-4 tw-gap-y-3 lg:tw-pb-1',
              )}
            >
              <div className="max-lg:tw-px-2">
                <div className="tw-flex tw-flex-row-reverse tw-text-xs">
                  <AgentLine entry={props.entry} agentMode={props.agentMode} topScore={props.topScore} />
                </div>
                <StoryUpperMatter
                  apiClient={props.apiClient}
                  feed={props.feed}
                  entry={props.entry}
                  goToFeed={props.goToFeed}
                  takeKbCursor={props.takeKbCursor}
                  onOpenStory={feedEntryOps.open}
                  compact={props.compact}
                  useTitleBadge
                  clampTitle={false}
                  rewind={props.rewind}
                  prefix={storyUpperMatterBadgePrefix}
                />
                <StoryLowerMatter
                  apiClient={props.apiClient}
                  feed={props.feed}
                  entry={props.entry}
                  goToFeed={props.goToFeed}
                  takeKbCursor={props.takeKbCursor}
                  onOpenStory={feedEntryOps.open}
                  compact={props.compact}
                  showMiniBadgeLine={false}
                  showFromLine
                  rewind={props.rewind}
                />
                <StoryUnderMatter
                  feed={props.feed}
                  entry={props.entry}
                  goToFeed={props.goToFeed}
                  compact={props.compact}
                  hidePostBody={props.compact}
                  hideBlurb={props.hideBlurb}
                />
              </div>
              {props.entry.discussion_preview || props.entry.trace_preview || props.entry.qna_preview ? (
                <div className={clsx('tw-flex tw-flex-col', props.compact ? 'tw-gap-y-1' : 'tw-gap-y-2')}>
                  {props.entry.discussion_preview ? (
                    <DiscussionPreviewMatter
                      discussion_preview={props.entry.discussion_preview}
                      onBadgeClick={() => {
                        feedEntryOps.explore();
                        props.takeKbCursor();
                      }}
                      onOpen={feedEntryOps.openDiscussionPreview!}
                    />
                  ) : null}
                  {props.entry.trace_preview ? (
                    <SourceTracePreviewMatter
                      trace_preview={props.entry.trace_preview}
                      onBadgeClick={() => {
                        feedEntryOps.explore();
                        props.takeKbCursor();
                      }}
                      onOpen={feedEntryOps.openTracePreview!}
                    />
                  ) : null}
                  {props.entry.qna_preview ? (
                    <QnaPreviewMatter
                      qna_preview={props.entry.qna_preview}
                      onBadgeClick={() => {
                        feedEntryOps.explore();
                        props.takeKbCursor();
                      }}
                    />
                  ) : null}
                </div>
              ) : null}
              {/* Action buttons shown in large width breakpoints if non-compact */}
              <div className={clsx('tw-max-w-sm tw-grow tw-items-end tw-hidden', props.compact ? null : ' lg:tw-flex')}>
                <FeedEntryActions
                  feed={props.feed}
                  entry={props.entry}
                  actions={feedEntryOps}
                  agentMode={props.agentMode}
                />
              </div>
            </div>
            {/* Action buttons shown if compact in all breakpoints except mobile */}
            {props.compact ? (
              <div className="tw-hidden sm:tw-block sm:tw-col-span-3">
                <FeedEntryCompactActions entry={props.entry} actions={feedEntryOps} />
              </div>
            ) : null}
            {showImage ? (
              <FeedEntryImage md={md} className="tw-col-span-12 lg:tw-col-span-4 lg:tw-flex lg:tw-items-center" />
            ) : null}
            {/* Action buttons shown in all modes smaller than large if non-compact.
          If compact, only shows in mobile breakpoint. */}
            <div
              className={clsx(
                'tw-max-w-sm tw-flex lg:tw-hidden tw-flex-col tw-col-span-12 tw-ml-2 tw-mb-1',
                props.compact ? 'sm:tw-hidden' : null,
              )}
            >
              <FeedEntryActions
                feed={props.feed}
                entry={props.entry}
                actions={feedEntryOps}
                agentMode={props.agentMode}
              />
            </div>
          </div>
        </div>
        <FeedEntryLeftSwipeTriangles
          count={leftSwipeFeeds.length}
          th={showImage && md['.tag'] === 'ready' ? md.image?.th : undefined}
          compact={props.compact}
        />
      </HoverTouchWheelMenu>
      <div className="tw-flex tw-flex-col">
        {props.feed.type['.tag'] === 'ego' &&
        props.entry['.tag'] === 'ego' &&
        props.entry.added_by &&
        props.entry.added_by.user_id !== props.accountInfo?.user_id ? (
          <div className={clsx('lg:tw-max-w-lg tw-px-2 tw-my-2', props.compact ? null : ' lg:tw-flex')}>
            <FeedEntryReply entry={props.entry} author={props.entry.added_by!} />
          </div>
        ) : null}
        <div ref={footerRef} />
        {loadShareFfaThread && props.entry['.tag'] === 'share' ? (
          <div className={clsx('tw-mt-2', props.compact ? 'tw-mb-2' : 'tw-mb-4')} onClick={e => e.stopPropagation()}>
            <ThreadFfa
              apiClient={props.apiClient}
              navToFeed={navToFeed}
              accountInfo={props.accountInfo}
              feed={props.feed}
              entry={props.entry}
              className="tw-px-2 tw-text-[0.9rem]"
              hideSectionHeaderTab
              kb={{
                kbNextSection,
                kbPrevSection,
                kbSelected: props.selected && kbSection === 'ffa-thread' ? 'going-down' : null,
                priority: -10,
              }}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

const FeedEntryListItem = React.memo(FeedEntryListItemBase, (prevProps, nextProps) => {
  // NOTE: takeKbCursor is omitted because it's difficult to make it avoid
  // changing. Correctness is not affected.
  return (
    prevProps.accountInfo === nextProps.accountInfo &&
    prevProps.entry === nextProps.entry &&
    prevProps.feed === nextProps.feed &&
    prevProps.selected === nextProps.selected &&
    prevProps.primaryKbOnly === nextProps.primaryKbOnly &&
    prevProps.inNewsfeed === nextProps.inNewsfeed &&
    prevProps.compact === nextProps.compact &&
    prevProps.bgColor === nextProps.bgColor &&
    prevProps.goToFeed === nextProps.goToFeed &&
    prevProps.apiClient === nextProps.apiClient &&
    prevProps.agentMode === nextProps.agentMode &&
    prevProps.topScore === nextProps.topScore &&
    prevProps.rewind === nextProps.rewind &&
    prevProps.hideBlurb === nextProps.hideBlurb &&
    prevProps.hideImage === nextProps.hideImage &&
    prevProps.newsfeedInclusionReason === nextProps.newsfeedInclusionReason
  );
});

export default FeedEntryListItem;
