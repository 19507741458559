import React from 'react';
import { TwitterTweetEmbed } from 'react-twitter-embed';

import Spinner from './lib/Spinner';
import { useThemeMode } from './ThemeModeGate';

const TwitterTweet = React.memo(
  (props: { tweetId: string; center?: boolean }) => {
    const themeMode = useThemeMode();
    return (
      // Target the css class injected by the twitter-embed 3rd party library.
      <div className={props.center ? '[&_.twitter-tweet]:tw-mx-auto' : undefined}>
        <TwitterTweetEmbed
          key={themeMode}
          options={{ theme: themeMode, width: 360 }}
          tweetId={props.tweetId}
          placeholder={
            <div className="tw-flex tw-justify-center tw-my-4">
              <Spinner />
            </div>
          }
        />
      </div>
    );
  },
  (prevProps, nextProps) => prevProps.tweetId === nextProps.tweetId,
);

export default TwitterTweet;
