import clsx from 'clsx';
import differenceInDays from 'date-fns/differenceInDays';
import parseISO from 'date-fns/parseISO';
import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React from 'react';
import { useDispatch } from 'react-redux';

import { MainActionCreators } from '../state/reducer';

import EgoMarkdown from './EgoMarkdown';
import useApiDo from './hooks/useApiDo';
import ArchiveIcon from './icon/ArchiveIcon';
import CommentIcon from './icon/CommentIcon';
import CpcIcon from './icon/CpcIcon';
import DiscussionIcon from './icon/DiscussionIcon';
import NoteIcon from './icon/NoteIcon';
import PaywallIcon from './icon/PaywallIcon';
import PrexoIcon from './icon/PrexoIcon';
import QnAIcon from './icon/QnAIcon';
import RedditIcon from './icon/RedditIcon';
import SourceTraceIcon from './icon/SourceTraceIcon';
import SubscriberIcon from './icon/SubscriberIcon';
import TrashIcon from './icon/TrashIcon';
import TwitterIcon from './icon/TwitterIcon';
import VideoIcon from './icon/VideoIcon';
import Badge from './lib/Badge';
import SmartFeedLink, { FeedLink } from './SmartFeedLink';

/**
 * Only returns the published date if it's specified and if it's over a day
 * different from the entry added date.
 */
function getEntrySuggestedPublishDate(entry: api.feed.IFeedEntryReference): string | null {
  const publishDate = cfe.ApiHelpers.getEntryPublishDate(entry);
  if (publishDate === null) {
    return null;
  } else {
    const publishTs = parseISO(publishDate);
    const entryTs = parseISO(entry.added_at);
    if (differenceInDays(entryTs, publishTs) > 1) {
      return publishDate;
    } else {
      return null;
    }
  }
}

function getRewindEntryPublishDate(entry: api.feed.IFeedEntryReference): string {
  const publishDate = cfe.ApiHelpers.getEntryPublishDate(entry);
  if (publishDate === null) {
    return entry.added_at;
  } else {
    return publishDate;
  }
}

export const StoryUpperMatter = (props: {
  apiClient: api.SuperegoClient;
  feed: api.feed.IFeedInfo;
  entry: api.feed.IFeedEntryReference;
  goToFeed: (feed: api.feed.IFeedInfo) => void;
  takeKbCursor?: () => void;
  onOpenStory?: (forceExternal?: boolean) => void;
  compact: boolean;
  useTitleBadge: boolean;
  clampTitle: boolean;
  rewind?: boolean;
  prefix?: React.ReactNode;
  useStimulusTitle?: boolean;
}) => {
  const hgcInfo = cfe.ApiHelpers.getHomegrownContentInfo(props.entry);
  const title = props.useStimulusTitle
    ? cfe.ApiHelpers.getStimulusTitle(props.entry)
    : cfe.ApiHelpers.getEntryTitle(props.entry);
  if (hgcInfo && !title) {
    return (
      <div className="tw-flex tw-flex-col tw-text-primary">
        <div>
          <ByLine
            apiClient={props.apiClient}
            goToFeed={props.goToFeed}
            feed={props.feed}
            entry={props.entry}
            compact={props.compact}
            prefix={props.prefix}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="tw-flex tw-items-start tw-gap-x-1 tw-text-primary">
        <div className={clsx('tw-grow', props.clampTitle ? 'tw-line-clamp-3' : 'tw-flex')}>
          <TitleLine
            entry={props.entry}
            takeKbCursor={props.takeKbCursor}
            onOpenStory={props.onOpenStory}
            suppressPublishedAt={!props.useTitleBadge}
            forcePublishedAt={!!props.rewind}
            compact={props.compact}
            prefix={props.prefix}
            useStimulusTitle={props.useStimulusTitle}
          />
        </div>
        {props.useTitleBadge ? (
          <div className="-tw-mt-1">
            <FeedEntryBadge entry={props.entry} compact={props.compact} />
          </div>
        ) : null}
      </div>
    );
  }
};

export const StoryLowerMatter = (props: {
  apiClient: api.SuperegoClient;
  feed: api.feed.IFeedInfo;
  entry: api.feed.IFeedEntryReference;
  goToFeed: (feed: api.feed.IFeedInfo) => void;
  takeKbCursor?: () => void;
  onOpenStory?: (forceExternal?: boolean) => void;
  compact: boolean;
  showMiniBadgeLine: boolean;
  showFromLine: boolean;
  showEditOptions?: boolean;
  rewind?: boolean;
}) => {
  const hgcInfo = cfe.ApiHelpers.getHomegrownContentInfo(props.entry);
  const title = cfe.ApiHelpers.getEntryTitle(props.entry);
  return (
    <div className="tw-flex tw-items-start tw-justify-between tw-gap-x-1 tw-text-primary tw-text-xs tw-leading-normal">
      <div className="tw-flex tw-flex-wrap tw-gap-x-1 tw-break-all tw-items-center tw-text-muted">
        {props.showMiniBadgeLine ? <MiniBadgeLine entry={props.entry} /> : null}
        {(!hgcInfo || !hgcInfo.debut || title) && props.showFromLine ? (
          // Clarification on the FromLine policy:
          // 1. If it's not an hgc, then it must have a title, so we need to show the FromLine.
          // 2. If it's not a debut hgc, then we need the fromLine to display how it got into this feed.
          // 3. If it has a title, then we need to show the FromLine (superset of #1).
          <div className="tw-font-semibold">
            <FromLine
              apiClient={props.apiClient}
              goToFeed={props.goToFeed}
              feed={props.feed}
              entry={props.entry}
              showEditOptions={props.showEditOptions}
            />
          </div>
        ) : null}
        <UrlPreviewLine feed={props.feed} entry={props.entry} ignoreViaForChannel={!props.showFromLine} />
      </div>
      {(!hgcInfo || !hgcInfo.debut || title) && props.showFromLine && !props.rewind ? (
        <EntryAddedAtDateLine ts={props.entry.added_at} />
      ) : null}
    </div>
  );
};

const UrlPreviewLine = (props: {
  feed: api.feed.IFeedInfo;
  entry: api.feed.IFeedEntryReference;
  ignoreViaForChannel?: boolean;
}) => {
  const urlPreview = cfe.ApiHelpers.getUrlPreviewFromEntry(props.entry);
  if (!urlPreview) {
    return null;
  }
  const showHostname = cfe.ApiHelpers.showHostname(props.entry, props.feed);
  const md = cfe.ApiHelpers.getEntryPrimaryMetadata(props.entry);
  if (md['.tag'] === 'ready' && md.channel) {
    return (
      <span className="tw-ml-1">
        {urlPreview.startsWith('x.com') || urlPreview.startsWith('twitter.com') ? (
          <TwitterIcon size="0.8rem" offsetUp />
        ) : urlPreview.startsWith('reddit.com') ? (
          <RedditIcon size="0.8rem" offsetUp />
        ) : null}
        {!md.channel.feed ||
        (md.channel.feed.feed_id !== props.feed.feed_id &&
          (props.ignoreViaForChannel || md.channel.feed.feed_id !== props.entry.via?.feed.feed_id)) ? (
          <>
            {urlPreview === 'youtube.com' ? ' YouTube ›' : null} {md.channel.name}
          </>
        ) : null}
      </span>
    );
  } else if (showHostname) {
    return <span>{urlPreview}</span>;
  } else {
    return null;
  }
};

export const StoryUnderMatter = (props: {
  feed: api.feed.IFeedInfo;
  entry: api.feed.IFeedEntryReference;
  goToFeed: (feed: api.feed.IFeedInfo) => void;
  compact: boolean;
  hidePostBody?: boolean;
  hideBlurb?: boolean;
  hideBlabber?: boolean;
}) => {
  const post = cfe.ApiHelpers.getEntryPostFull(props.entry);
  const blurb = cfe.ApiHelpers.getEntryBlurb(props.entry);
  const blabber = cfe.ApiHelpers.getBlabber(props.entry);
  return (
    <div className="tw-text-primary">
      {blabber && !props.hideBlabber ? <QuotedBlabber blabber={blabber} navToFeed={props.goToFeed} /> : null}
      {!props.hideBlabber && props.entry.via?.quote ? (
        <div>
          <span className="tw-text-xs">
            <span className="tw-font-semibold">{props.entry.via.quote.author.name}</span>{' '}
            <span className="tw-font-semibold tw-text-muted">wrote</span>
          </span>
          <QuotedBlabber blabber={props.entry.via.quote.blabber} navToFeed={props.goToFeed} />
        </div>
      ) : null}
      {
        // Skip blank content (long posts) to avoid unnecessary margins
        post?.content && !props.hidePostBody ? (
          // notif feeds don't require a margin because there's no lower
          // matter nor action btns.
          <div className={clsx('tw-mt-1', props.feed.type['.tag'] !== 'notif' ? 'tw-mb-2' : null)}>
            <EgoMarkdown variant="post" content={post.content} goToFeed={props.goToFeed} />
          </div>
        ) : blurb &&
          !props.hideBlurb &&
          !props.compact &&
          (props.hideBlabber || (!blabber && props.entry.via?.quote === undefined)) ? (
          <div className="tw-mt-2 tw-text-sm tw-break-words">
            <EgoMarkdown variant="blurb" content={blurb} goToFeed={props.goToFeed} />
          </div>
        ) : null
      }
    </div>
  );
};

export const StoryDebutMatter = (props: {
  entry: api.feed.IFeedEntryReference;
  debut: api.stimulus.IDebut;
  navToFeed: (feed: api.feed.IFeedInfo) => void;
}) => {
  if (props.debut.feed.type['.tag'] === 'ego') {
    // Assume that the feed is the same as the poster so it would be redundant
    // to state its origination feed.
    return null;
  }
  return (
    <div className="tw-text-xs">
      <span className="tw-font-semibold tw-text-muted">
        Debuted in <FeedLink navToFeed={props.navToFeed} feed={props.debut.feed} className="tw-text-muted" />
      </span>
    </div>
  );
};

export const Blabber = (props: { blabber: string; navToFeed: (feed: api.feed.IFeedInfo) => void }) => (
  <EgoMarkdown variant="blabber" content={props.blabber} goToFeed={props.navToFeed} />
);

export const QuoteBlock = (props: { children: React.ReactNode }) => (
  <div
    className={clsx(
      'tw-my-2 tw-px-2 tw-py-1 tw-ml-2',
      'tw-border tw-border-y-0 tw-border-r-0 tw-border-solid tw-border-l-4 tw-rounded-lg',
      'tw-border-perpul-light dark:tw-border-perpul-dark',
    )}
  >
    {props.children}
  </div>
);

export const QuotedBlabber = (props: { blabber: string; navToFeed: (feed: api.feed.IFeedInfo) => void }) => (
  <QuoteBlock>
    <Blabber blabber={props.blabber} navToFeed={props.navToFeed} />
  </QuoteBlock>
);

export const ByLine = (props: {
  apiClient: api.SuperegoClient;
  goToFeed: (feed: api.feed.IFeedInfo) => void;
  feed: api.feed.IFeedInfo;
  entry: api.feed.IFeedEntryReference;
  compact: boolean;
  prefix?: React.ReactNode;
}) => {
  const bylineParts = cfe.MatterHelpers.getBylineParts(props.feed, props.entry);
  const publishedAt = getEntrySuggestedPublishDate(props.entry);
  const md = cfe.ApiHelpers.getEntryMetadata(props.entry);
  const hgcInfo = cfe.ApiHelpers.getHomegrownContentInfo(props.entry);
  if (!bylineParts.n1) {
    return null;
  }
  return (
    <div
      className={clsx(
        'tw-flex tw-gap-x-2',
        props.compact ? 'tw-text-[0.95rem]' : 'tw-text-base',
        'tw-leading-tight tw-font-semibold tw-break-words tw-text-primary',
      )}
    >
      <div
        className={clsx(
          'tw-text-balance',
          props.entry.for_viewer.last_visit?.when ? 'tw-text-muted' : 'tw-text-primary',
        )}
      >
        {props.prefix}
        <MatterPartComponent apiClient={props.apiClient} goToFeed={props.goToFeed} part={bylineParts.n1} />
        <span>
          {bylineParts.p1 ? (
            <>
              {' '}
              {bylineParts.p1.v1}
              {bylineParts.p1.n2 ? (
                <>
                  {' '}
                  in{' '}
                  <MatterPartComponent apiClient={props.apiClient} goToFeed={props.goToFeed} part={bylineParts.p1.n2} />
                </>
              ) : null}
            </>
          ) : null}
          {publishedAt ? (
            // Match the appearance of a timestamp for a url-based story
            // that has a different publish ts from its entry ts.
            <PublishedAtDateLine publishedAt={publishedAt} className={props.compact ? 'tw-text-xs' : 'tw-text-sm'} />
          ) : null}
        </span>
        {props.entry['.tag'] === 'notif' ? (
          <span className={clsx('tw-ml-2 tw-font-normal text-muted', props.compact ? 'tw-text-xs' : 'tw-text-sm')}>
            | Archive <ArchiveIcon size="0.875rem" offsetUp /> to clear
          </span>
        ) : null}
      </div>
      <div className="tw-grow">
        <div className="-tw-mt-1">
          <FeedEntryBadge entry={props.entry} compact={props.compact} />
        </div>
      </div>
      <div className="tw-flex tw-gap-x-1 tw-items-center">
        {/* If it's a debut hgc, then there's no FromLine, so add a
        right-aligned timestamp. */}
        {md['.tag'] === 'ready' && md.published_at && hgcInfo?.debut ? (
          <EntryAddedAtDateLine ts={md.published_at} className="tw-font-normal" />
        ) : null}
      </div>
    </div>
  );
};

export const TitleLine = (props: {
  entry: api.feed.IFeedEntryReference;
  takeKbCursor?: () => void;
  onOpenStory?: (forceExternal?: boolean) => void;
  suppressPublishedAt: boolean;
  forcePublishedAt: boolean;
  compact: boolean;
  prefix?: React.ReactNode;
  useStimulusTitle?: boolean;
}) => {
  const primaryUrl = props.entry.strong_ref?.url ?? props.entry.url;
  const href = cfe.ApiHelpers.isUrlOpenable(primaryUrl)
    ? primaryUrl
    : cfe.ApiHelpers.getUrlPathnameForEntry(props.entry.entry_id);
  const title = props.useStimulusTitle
    ? cfe.ApiHelpers.getStimulusTitle(props.entry)
    : cfe.ApiHelpers.getEntryTitleBounded(props.entry);
  const publishedAt = props.forcePublishedAt
    ? getRewindEntryPublishDate(props.entry)
    : getEntrySuggestedPublishDate(props.entry);
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener"
      className={clsx(
        'hover:tw-no-underline',
        props.entry.for_viewer.last_visit?.when ? 'tw-text-muted' : 'tw-text-primary',
        'tw-text-balance',
      )}
      onClick={e => {
        if (props.takeKbCursor) {
          props.takeKbCursor();
        }
        e.stopPropagation();
        e.preventDefault();
        if (props.onOpenStory) {
          props.onOpenStory(e.ctrlKey || e.metaKey);
        }
      }}
    >
      <span
        className={clsx(
          props.compact ? 'tw-text-[0.95rem]' : 'tw-text-base',
          'tw-leading-tight tw-font-semibold tw-break-words tw-text-primary',
        )}
      >
        {props.prefix}
        {title}
      </span>
      {!props.suppressPublishedAt && publishedAt ? (
        <PublishedAtDateLine publishedAt={publishedAt} className={props.compact ? 'tw-text-xs' : 'tw-text-sm'} />
      ) : null}
    </a>
  );
};

/**
 * Appears following an entry's title whether that's a story title or its
 * byline.
 */
const PublishedAtDateLine = (props: { publishedAt: string; className: string }) => (
  <span className={clsx('tw-ml-2 tw-text-muted tw-font-semibold', props.className)}>
    <DateLine ts={props.publishedAt} />
  </span>
);

const EntryAddedAtDateLine = (props: { ts: string; className?: string }) => (
  <span className={clsx('tw-shrink-0 tw-text-muted tw-text-xs', props.className)}>
    <DateLine ts={props.ts} />
  </span>
);

export const AgentLine = (props: {
  entry: api.feed.IFeedEntryReference;
  agentMode: boolean;
  topScore?: number;
  omitScoreLine?: boolean;
}) => {
  return (
    <div className="tw-flex tw-items-center">
      {props.agentMode && props.entry.for_staff ? (
        <>
          {(props.entry.feed_ref_counts?.ego ?? 0) > 0 && !props.entry.for_staff.author_reach_out ? (
            <Badge variant="danger" className="tw-mr-2">
              Contact Author
            </Badge>
          ) : null}
          {!props.entry.for_staff.qc_reviewed ? (
            <Badge variant="warn" className="tw-mr-2">
              Unreviewed
            </Badge>
          ) : null}
          {props.entry.for_staff.pruned ? (
            <Badge variant="info" className="tw-mr-2">
              Pruned: {props.entry.for_staff.pruned.reason['.tag']}
            </Badge>
          ) : null}
          {!props.entry.for_staff.topics_assigned ? (
            <Badge variant="warn" className="tw-mr-2">
              No topics
            </Badge>
          ) : !props.entry.natural_topic ? (
            <Badge variant="warn" className="tw-mr-2">
              No natural topic
            </Badge>
          ) : null}
          {!props.omitScoreLine ? (
            <span className="tw-text-muted">
              <ScoreLine entry={props.entry} topScore={props.topScore} />
            </span>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export const ScoreLine = (props: { entry: api.feed.IFeedEntryReference; topScore?: number }) => {
  if (!props.entry.for_staff) {
    throw Error('Expected agent');
  }
  const scoreStim = props.entry.for_staff.score.stim / 3600;
  const scoreStaff = props.entry.for_staff.score.staff / 3600;
  const scoreRss = props.entry.for_staff.score.rss / 3600;
  const scoreInter = (props.entry.for_staff.score.inter ?? 0) / 3600;
  const scoreInter0 = (props.entry.for_staff.score.inter0 ?? 0) / 3600;
  const title = `stim ${scoreStim.toFixed(2)} / staff ${scoreStaff.toFixed(2)} / rss ${scoreRss.toFixed(
    2,
  )} / inter ${scoreInter.toFixed(2)} / inter0 ${scoreInter0.toFixed(2)}`;
  const scoreDifferential = props.topScore
    ? Math.round((props.topScore - cfe.ApiHelpers.computeScore(props.entry)) / 3600)
    : undefined;
  return (
    <span title={title}>
      {scoreStim.toFixed()} / {scoreStaff.toFixed()} / {scoreRss.toFixed()}
      <span> / {props.entry.for_staff.score.inter !== undefined ? scoreInter.toFixed() : <>&ndash;</>}</span>
      <span> / {props.entry.for_staff.score.inter0 !== undefined ? scoreInter0.toFixed() : <>&ndash;</>}</span>
      {props.topScore ? <span> | {scoreDifferential}</span> : null}
    </span>
  );
};

const MatterPartComponent = (props: {
  apiClient: api.SuperegoClient;
  goToFeed: (feed: api.feed.IFeedInfo) => void;
  part: cfe.MatterHelpers.MatterPart;
}) => {
  const inner = <span>{props.part.s}</span>;
  const className = props.part.bold
    ? 'tw-text-primary tw-font-semibold hover:tw-text-primary'
    : 'tw-text-muted hover:tw-text-muted';
  if (props.part.kind === 'string') {
    return inner;
  } else if (props.part.kind === 'feed') {
    return (
      <FeedLink navToFeed={props.goToFeed} feed={props.part.feed} className={className}>
        {inner}
        {props.part.feed.type['.tag'] === 'ego' && props.part.feed.publisher!.is_subscriber ? (
          <>
            {' '}
            <SubscriberIcon size="0.875rem" />
          </>
        ) : null}
      </FeedLink>
    );
  } else {
    return (
      <SmartFeedLink
        apiClient={props.apiClient}
        goToFeed={props.goToFeed}
        feedRef={`u/${props.part.user.user_id}`}
        displayHref={`/${props.part.user.username}`}
        className={className}
      >
        {inner}
        {props.part.user.is_subscriber ? (
          <>
            {' '}
            <SubscriberIcon size="0.875rem" />
          </>
        ) : null}
      </SmartFeedLink>
    );
  }
};

export const FromLine = (props: {
  apiClient: api.SuperegoClient;
  goToFeed: (feed: api.feed.IFeedInfo) => void;
  feed: api.feed.IFeedInfo;
  entry: api.feed.IFeedEntryReference;
  showEditOptions?: boolean;
}) => {
  const fromlineParts = cfe.MatterHelpers.getFromlineParts(props.feed, props.entry);
  return (
    <div>
      <span className="tw-text-muted">
        {fromlineParts.p1?.redundantN1V1 && fromlineParts.p1.n2 ? (
          <>
            <MatterPartComponent apiClient={props.apiClient} goToFeed={props.goToFeed} part={fromlineParts.p1.n2} />
            {fromlineParts.p1.n3 ? (
              <>
                {' '}
                from{' '}
                <MatterPartComponent apiClient={props.apiClient} goToFeed={props.goToFeed} part={fromlineParts.p1.n3} />
              </>
            ) : null}
          </>
        ) : (
          <>
            <MatterPartComponent apiClient={props.apiClient} goToFeed={props.goToFeed} part={fromlineParts.n1} />
            {fromlineParts.p1 ? (
              <>
                {' '}
                {fromlineParts.p1.v1}
                {fromlineParts.p1.n2 ? (
                  <>
                    {' '}
                    {props.feed.type['.tag'] === 'share' ? 'with' : 'in'}{' '}
                    <MatterPartComponent
                      apiClient={props.apiClient}
                      goToFeed={props.goToFeed}
                      part={fromlineParts.p1.n2}
                    />
                  </>
                ) : null}
                {fromlineParts.p1.n3 ? (
                  <>
                    {' '}
                    from{' '}
                    <MatterPartComponent
                      apiClient={props.apiClient}
                      goToFeed={props.goToFeed}
                      part={fromlineParts.p1.n3}
                    />
                    {props.showEditOptions && props.entry.via ? (
                      <RemoveViaButton
                        apiClient={props.apiClient}
                        feed={props.feed}
                        entry={props.entry}
                        className="tw-ml-1"
                      />
                    ) : null}
                  </>
                ) : null}
              </>
            ) : null}
          </>
        )}
      </span>
    </div>
  );
};

const RemoveViaButton = (props: {
  apiClient: api.SuperegoClient;
  feed: api.feed.IFeedInfo;
  entry: api.feed.IFeedEntryReference;
  className?: string;
}) => {
  const dispatch = useDispatch();
  const { apiDo: apiFeedEntryRemoveVia, okToast } = useApiDo(props.apiClient, props.apiClient.feedEntryRemoveVia);
  const { apiDo: apiFeedEntryRefresh } = useApiDo(props.apiClient, props.apiClient.feedEntryRefresh);
  return (
    <span
      role="button"
      className={props.className}
      onClick={() => {
        apiFeedEntryRemoveVia(
          { entry_id: props.entry.entry_id },
          {
            onResult: () => {
              okToast('Removed via');
              apiFeedEntryRefresh(
                { entry_id: props.entry.entry_id },
                {
                  onResult: res => {
                    dispatch(MainActionCreators.updateFeedEntry(props.feed.feed_id, res));
                  },
                },
              );
            },
          },
        );
      }}
    >
      <TrashIcon size="0.8rem" />
    </span>
  );
};

export const DateLine = (props: { ts: string }) => {
  const [tsShort, tsLong] = cfe.Formatter.getTsFormats(props.ts);
  return <span title={tsLong}>{tsShort}</span>;
};

export const FeedEntryBadge = (props: { entry: api.feed.IFeedEntryReference; compact: boolean }) => {
  const md = cfe.ApiHelpers.getEntryMetadata(props.entry);
  const viewMode = cfe.ApiHelpers.getViewMode(props.entry);
  if (viewMode.cpc?.default) {
    return (
      <StoryBadge progress={props.entry.for_viewer.last_visit?.progress ?? 0}>
        {viewMode.cpc.is_prexo || (md['.tag'] === 'ready' && md.post?.is_prexo) ? (
          <PrexoIcon size="1.15rem" />
        ) : md['.tag'] === 'ready' && md.content_type['.tag'] === 'video' ? (
          <>
            <VideoIcon size="1.25rem" />
            {md.content_type.duration ? (
              <span className="tw-ml-1 tw-text-xs tw-font-semibold tw-leading-none">
                {cfe.ApiHelpers.getDurationShortStringFromSecs(md.content_type.duration)}
              </span>
            ) : null}
          </>
        ) : (
          <CpcIcon size="1.15rem" />
        )}
      </StoryBadge>
    );
  }
  const primaryUrl = props.entry.strong_ref?.url ?? props.entry.url;
  if (md['.tag'] === 'ready') {
    if (md.paywalled) {
      return (
        <div className="tw-flex -tw-mt-0.5">
          <a
            className="tw-ml-1"
            href={`https://archive.today/newest/${primaryUrl}`}
            target="_blank"
            onClick={e => e.stopPropagation()}
          >
            <PaywallBadge size={props.compact ? 22 : 24} />
          </a>
        </div>
      );
    } else if (md.content_type['.tag'] === 'pdf') {
      return (
        <StoryBadge progress={0}>
          <span className="tw-font-semibold">PDF</span>
        </StoryBadge>
      );
    } else if (md.content_type['.tag'] === 'video') {
      return (
        <StoryBadge progress={props.entry.for_viewer.last_visit?.progress ?? 0}>
          <>
            <VideoIcon size="1.25rem" />
            {md.content_type.duration ? (
              <span className="tw-ml-1 tw-text-xs tw-font-semibold tw-leading-none">
                {cfe.ApiHelpers.getDurationShortStringFromSecs(md.content_type.duration)}
              </span>
            ) : null}
          </>
        </StoryBadge>
      );
    }
  }
  return null;
};

const StoryBadge = (props: { children: React.ReactNode; progress: number }) => (
  <ProgressBorder progress={props.progress}>
    <div
      className={clsx(
        'tw-flex flex-row tw-items-center tw-justify-center',
        'tw-py-1 tw-px-2',
        'tw-bg-gray-300 dark:tw-bg-gray-600',
        'tw-h-[1.6rem] tw-whitespace-nowrap',
      )}
    >
      {props.children}
    </div>
  </ProgressBorder>
);

const ProgressBorder = (props: { children: React.ReactNode; progress: number }) => (
  <div className="tw-flex tw-items-stretch">
    <div className="tw-flex tw-flex-col-reverse tw-w-[2px]">
      <div
        className="tw-bg-red-700 tw-w-full tw-h-full"
        style={{ height: `${Math.max((props.progress - 75) * 4, 0)}%` }}
      ></div>
    </div>
    <div>
      <div className="tw-bg-red-700 tw-h-[2px]" style={{ width: `${Math.min(props.progress * 4, 100)}%` }}></div>
      {props.children}
      <div className="tw-flex tw-flex-row-reverse">
        <div
          className="tw-bg-red-700 tw-h-[2px]"
          style={{ width: `${Math.min(Math.max(props.progress - 50, 0) * 4, 100)}%` }}
        ></div>
      </div>
    </div>
    <div className="tw-w-[2px]">
      <div
        className="tw-bg-red-700 tw-w-full tw-h-full"
        style={{ height: `${Math.min(Math.max(props.progress - 25, 0) * 4, 100)}%` }}
      ></div>
    </div>
  </div>
);

export const PaywallBadge = (props: { size: number }) => (
  <span className="tw-text-red-700">
    <PaywallIcon size={props.size} />
  </span>
);

export const DiscussionBadge = () => (
  <span className="tw-mr-1 tw-leading-none" title="This story has discussions.">
    <DiscussionIcon size="0.95rem" />
  </span>
);

export const DiscussionPreviewMatter = (props: {
  discussion_preview: api.feed.IDiscussionPreview;
  onBadgeClick: () => void;
  onOpen: () => void;
}) => (
  <PreviewMatterRow
    count={props.discussion_preview.discussion_count}
    badge={<DiscussionIcon size="1rem" />}
    onBadgeClick={props.onBadgeClick}
    onRowClick={props.onOpen}
  >
    <a
      href={props.discussion_preview.url}
      target="_blank"
      onClick={e => e.stopPropagation()}
      onAuxClick={e => e.stopPropagation()}
    >
      {props.discussion_preview.name}
    </a>
    {props.discussion_preview.comment_count ? (
      <span className="tw-text-muted tw-ml-2">
        <CommentIcon size="0.9rem" offsetUp />
        <span className="tw-mr-1" />
        {cfe.Formatter.abbreviateNumber(props.discussion_preview.comment_count)}
        {props.discussion_preview.discussion_count > 1 ? (
          <span>
            {' '}
            &bull;{' '}
            <span
              className="tw-link"
              onClick={e => {
                e.stopPropagation();
                props.onBadgeClick();
              }}
            >
              {props.discussion_preview.discussion_count - 1} more
            </span>
          </span>
        ) : null}
      </span>
    ) : null}
  </PreviewMatterRow>
);

export const SourceTracePreviewMatter = (props: {
  trace_preview: api.feed.IThreadPreview;
  onBadgeClick: () => void;
  onOpen: () => void;
}) => {
  const link = cfe.ThreadHelpers.parsePureMarkdownLink(props.trace_preview.body);
  if (!link) {
    return null;
  }
  const [text, url] = link;
  return (
    <PreviewMatterRow
      count={props.trace_preview.comment_count}
      badge={<SourceTraceIcon size="1rem" />}
      onBadgeClick={props.onBadgeClick}
      onRowClick={props.onOpen}
    >
      <a href={url} target="_blank" onClick={e => e.preventDefault()}>
        {text}
      </a>
    </PreviewMatterRow>
  );
};

export const QnaPreviewMatter = (props: { qna_preview: api.feed.IThreadPreview; onBadgeClick: () => void }) => (
  <PreviewMatterRow
    count={props.qna_preview.comment_count}
    badge={<QnAIcon size="1rem" />}
    onBadgeClick={props.onBadgeClick}
    onRowClick={props.onBadgeClick}
  >
    <span
      onClick={e => {
        e.stopPropagation();
        props.onBadgeClick();
      }}
    >
      {props.qna_preview.body}
    </span>
  </PreviewMatterRow>
);

const PreviewMatterRow = (props: {
  count: number;
  badge: React.ReactNode;
  onBadgeClick: () => void;
  onRowClick?: () => void;
  children: React.ReactNode;
}) => (
  <div className="tw-flex tw-items-center tw-text-[0.9rem]">
    <div className="tw-shrink-0 tw-flex tw-justify-end tw-w-[4.5rem] tw-text-primary tw-text-sm tw-font-mono">
      <div
        className={clsx(
          'tw-inline-flex tw-items-center',
          'tw-text-[90%] tw-font-semibold tw-rounded tw-px-2',
          'tw-bg-gray-300 dark:tw-bg-gray-600 hover:tw-opacity-80',
        )}
        onClick={e => {
          e.stopPropagation();
          props.onBadgeClick();
        }}
      >
        <div className="tw-inline-flex tw-mt-[0.1rem] tw-mr-1">{cfe.Formatter.abbreviateNumber(props.count)}</div>
        {props.badge}
      </div>
    </div>
    <div
      className={clsx(
        'tw-pl-2 tw-grow',
        props.onRowClick ? 'tw-rounded hover:tw-bg-gray-200 dark:hover:tw-bg-gray-700' : null,
      )}
      onClick={
        props.onRowClick
          ? e => {
              e.stopPropagation();
              if (props.onRowClick) {
                props.onRowClick();
              }
            }
          : undefined
      }
      onAuxClick={
        props.onRowClick
          ? e => {
              if (e.button === 1) {
                e.stopPropagation();
                if (props.onRowClick) {
                  props.onRowClick();
                }
              }
            }
          : undefined
      }
    >
      {props.children}
    </div>
  </div>
);

export const DiscussionMiniBadge = (props: { entry: api.feed.IFeedEntryReference }) => {
  if (props.entry.discussion_preview) {
    return (
      <PreviewMatterMiniBadge
        count={props.entry.discussion_preview.discussion_count}
        icon={<CommentIcon size="0.9rem" offsetUp />}
      />
    );
  } else {
    return null;
  }
};

export const SourceTraceMiniBadge = (props: { entry: api.feed.IFeedEntryReference }) => {
  if (props.entry.trace_preview) {
    return (
      <PreviewMatterMiniBadge count={props.entry.trace_preview.comment_count} icon={<SourceTraceIcon size="1rem" />} />
    );
  } else {
    return null;
  }
};

export const QnAMiniBadge = (props: { entry: api.feed.IFeedEntryReference }) => {
  if (props.entry.qna_preview) {
    return <PreviewMatterMiniBadge count={props.entry.qna_preview.comment_count} icon={<QnAIcon size="1rem" />} />;
  } else {
    return null;
  }
};

const PreviewMatterMiniBadge = (props: { count: number; icon: React.ReactNode }) => (
  <div className="tw-shrink-0 tw-flex tw-justify-end tw-text-primary tw-text-sm tw-font-mono">
    <div
      className={clsx(
        'tw-inline-flex tw-items-center',
        'tw-text-xs tw-font-semibold tw-h-[1.35rem] tw-rounded tw-px-2',
        'tw-bg-gray-300 dark:tw-bg-gray-600 hover:tw-opacity-80',
      )}
    >
      <div className="tw-inline-flex tw-mt-[0.1rem] tw-mr-1">{cfe.Formatter.abbreviateNumber(props.count)}</div>
      {props.icon}
    </div>
  </div>
);

const ContentTypeMiniBadge = (props: { entry: api.feed.IFeedEntryReference }) => {
  const md = cfe.ApiHelpers.getEntryMetadata(props.entry);
  const viewMode = cfe.ApiHelpers.getViewMode(props.entry);
  if (viewMode.cpc?.default) {
    return (
      <MiniStoryBadge progress={props.entry.for_viewer.last_visit?.progress ?? 0}>
        {md['.tag'] === 'ready' && md.post?.is_prexo ? <PrexoIcon size="1.15rem" /> : <CpcIcon size="1.15rem" />}
      </MiniStoryBadge>
    );
  }
  if (md['.tag'] !== 'ready') {
    return null;
  }
  return (
    <>
      {md.content_type['.tag'] === 'video' ? (
        <MiniStoryBadge progress={props.entry.for_viewer.last_visit?.progress ?? 0}>
          <VideoIcon size="1.25rem" />
          {md.content_type.duration ? (
            <span className="tw-ml-1 tw-text-xs tw-font-semibold tw-leading-none">
              {cfe.ApiHelpers.getDurationShortStringFromSecs(md.content_type.duration)}
            </span>
          ) : null}
        </MiniStoryBadge>
      ) : md.content_type['.tag'] === 'pdf' ? (
        <MiniStoryBadge progress={0}>
          <span className="tw-font-semibold">PDF</span>
        </MiniStoryBadge>
      ) : null}
    </>
  );
};

const MiniStoryBadge = (props: { children: React.ReactNode; progress: number }) => (
  <ProgressBorder progress={props.progress}>
    <div
      className={clsx(
        'tw-flex flex-row tw-items-center tw-justify-center',
        'tw-py-1 tw-px-2',
        'tw-bg-gray-300 dark:tw-bg-gray-600',
        'tw-h-[1.6rem]',
        'tw-text-primary tw-text-xs',
      )}
    >
      {props.children}
    </div>
  </ProgressBorder>
);

const BlabberMiniBadge = () => <NoteIcon size="0.9rem" />;

export const MiniBadgeLine = (props: { entry: api.feed.IFeedEntryReference }) => {
  const md = cfe.ApiHelpers.getEntryMetadata(props.entry);
  return (
    <div className="tw-flex tw-items-center tw-gap-1">
      <ContentTypeMiniBadge entry={props.entry} />
      {md['.tag'] === 'ready' && md.paywalled ? (
        <div className="tw-inline tw-leading-none">
          <PaywallBadge size={18} />
        </div>
      ) : null}
      {cfe.ApiHelpers.getBlabber(props.entry) ? <BlabberMiniBadge /> : null}
      <div className="tw-flex tw-gap-1">
        <DiscussionMiniBadge entry={props.entry} />
        <SourceTraceMiniBadge entry={props.entry} />
        <QnAMiniBadge entry={props.entry} />
      </div>
    </div>
  );
};
