import clsx from 'clsx';
import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';

import React, { useEffect, useState } from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { batch, useDispatch, useSelector } from 'react-redux';
import { scrollTo } from 'scroll-js';

import { MainActionCreators } from '../state/reducer';
import * as store from '../state/store';

import useFeedEntryOps, { useFeedEntryFeedbackOps } from './hooks/useFeedEntryOps';
import useNav, { useAppLoc } from './hooks/useNav';
import useStoryOpener from './hooks/useStoryOpener';
import { AccountInfoMaybe } from './hooks/useUserMeInternal';

import FeedEntryActions from './FeedEntryActions';
import FeedEntryImage from './FeedEntryImage';
import FeedEntryLeftSwipeTriangles from './FeedEntryLeftSwipeTrianges';
import { StoryLowerMatter, StoryUpperMatter } from './FeedEntryMatter';
import FollowButton from './FollowButton';
import HoverTouchWheelMenu from './HoverTouchWheelMenu';
import ClockIcon from './icon/ClockIcon';
import { useKeyPress } from './KeyPressContext';
import SmartFeedLink from './SmartFeedLink';

const FeedEntryHorizontalListBase = (props: {
  apiClient: api.SuperegoClient;
  accountInfo: AccountInfoMaybe;
  feedId: string;
  selected: boolean;
  smHeader?: boolean;
  compact: boolean;
  showArchived: boolean;
  filterVideosOnly: boolean;
  urlToSkip?: string;
  startEntryId?: string;
  subtitle?: string;
  titleFullWidth?: boolean;
  headerClass?: string;
  showFollowButton: boolean;
  agentMode: boolean;
  unmountSelf?: () => void;
}) => {
  const dispatch = useDispatch();
  const { navToEntry, navToFeed } = useNav();
  const { isExploreOpen, isExploreOrCpcOpen } = useAppLoc();
  const { storyOpener, discussionPreviewOpener } = useStoryOpener();
  const feed = useSelector<store.IAppState, api.feed.IFeedInfo | undefined>(state => state.feeds.get(props.feedId));

  const arg = {
    feed_id: props.feedId,
    limit: 30,
  };
  const argKey = JSON.stringify(arg);
  const { result } = cfe.ApiHook.useApiReadIterCache(
    props.apiClient,
    props.apiClient.feedEntryIter,
    { feed_id: props.feedId, limit: 30 },
    props.apiClient.feedEntryIterNext,
    { limit: 10 },
    res => res,
    value => dispatch(MainActionCreators.apiCacheSetFeedEntries(props.feedId, argKey, value)),
    () =>
      useSelector<store.IAppState, cfe.ApiHook.CacheUnit<cfe.ApiData.Data<api.feed.IEntryIterResult[]>>>(
        appState => appState.apiCache.feedEntries.get(argKey) ?? cfe.ApiHook.getCacheEmptySingleton(),
      ),
    false,
    {
      onResult: res => {
        batch(() => {
          dispatch(MainActionCreators.updateFeed(res.feed));
          res.entries.map(entry => entry.via && dispatch(MainActionCreators.updateFeed(entry.via.feed)));
          res.entries.map(entry => entry.natural_topic && dispatch(MainActionCreators.updateFeed(entry.natural_topic)));
          res.entries.map(entry => entry.works_feed && dispatch(MainActionCreators.updateFeed(entry.works_feed)));
        });
      },
    },
    120,
    undefined,
    true,
  );

  const [kbCursorIndex, setKbCursorIndex] = useState(0);

  const feedEntries = cfe.ApiData.reduce(
    cfe.ApiData.map(result, res => res.entries),
    [],
    (curVal, prevVal) => {
      return curVal.concat(prevVal);
    },
  );

  const MAX_ENTRIES = 10;
  const filteredEntries = cfe.NewsfeedHelpers.filterNewsfeedData(
    feed ? cfe.ApiData.map(feedEntries, entry => ({ feed, entry })) : cfe.ApiData.UnknownValue,
    props.showArchived,
    props.filterVideosOnly,
    !props.agentMode,
    props.urlToSkip,
    props.startEntryId,
  );

  // Unmount itself from parent so that it doesn't take up a keyboard cursor slot.
  useEffect(() => {
    if (!props.unmountSelf) {
      return;
    }
    if (cfe.ApiData.hasData(filteredEntries) && filteredEntries.data.length === 0) {
      props.unmountSelf();
    }
  }, [filteredEntries.kind, cfe.ApiData.getData(filteredEntries)?.length ?? 0]);

  useEffect(() => {
    // Reset cursor if it ever goes out of bounds.
    if (kbCursorIndex > 0 && cfe.ApiData.hasData(filteredEntries) && kbCursorIndex >= filteredEntries.data.length) {
      setKbCursorIndex(0);
    }
  }, [kbCursorIndex]);

  let kbSelectedEntry: api.newsfeed.INewsfeedEntry | null = null;
  let kbNextEntry: api.newsfeed.INewsfeedEntry | null = null;
  let kbPrevEntry: api.newsfeed.INewsfeedEntry | null = null;
  if (cfe.ApiData.hasData(filteredEntries)) {
    if (kbCursorIndex < filteredEntries.data.length) {
      kbSelectedEntry = filteredEntries.data[kbCursorIndex];
    }
    if (kbCursorIndex + 1 < filteredEntries.data.length && kbCursorIndex + 1 < MAX_ENTRIES) {
      kbNextEntry = filteredEntries.data[kbCursorIndex + 1];
    }
    if (kbCursorIndex - 1 >= 0) {
      kbPrevEntry = filteredEntries.data[kbCursorIndex - 1];
    }
  }

  useEffect(() => {
    if (props.selected && isExploreOpen && feed && kbSelectedEntry) {
      navToEntry(feed, kbSelectedEntry.entry);
    }
  }, [props.selected]);

  // FIXME: This won't work for keyboard controls in a horizontal list in explore.
  useKeyPress(
    'l',
    () => {
      // l: Move to next entry.
      if (feed && kbNextEntry) {
        setKbCursorIndex(kbCursorIndex + 1);
        if (isExploreOpen) {
          navToEntry(feed, kbNextEntry.entry);
        }
      }
    },
    !props.selected,
  );

  useKeyPress(
    'h',
    () => {
      // h: Move to prev feed entry.
      if (feed && kbPrevEntry) {
        setKbCursorIndex(kbCursorIndex - 1);
        if (isExploreOpen) {
          navToEntry(feed, kbPrevEntry.entry);
        }
      } else if (feed && !kbPrevEntry) {
        navToFeed(feed);
      }
    },
    !props.selected,
  );

  useKeyPress(
    'i',
    () => {
      // i: Open/close explore modal for entry
      if (feed && kbSelectedEntry && !isExploreOpen) {
        navToEntry(feed, kbSelectedEntry.entry);
      }
    },
    // Delegate responsibility of closing explore to FeedPage.
    !props.selected || isExploreOpen,
  );

  useKeyPress(
    'O',
    () => {
      // shift + o: Open discussion in new tab if available, otherwise open entry url.
      if (kbSelectedEntry) {
        discussionPreviewOpener(props.feedId, kbSelectedEntry.entry);
      }
    },
    !props.selected,
  );

  useKeyPress(
    'o',
    () => {
      // o: Open primary url in a new tab.
      if (feed && kbSelectedEntry && !isExploreOpen) {
        storyOpener(feed, kbSelectedEntry.entry);
      }
    },
    !props.selected,
  );

  const divRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (!props.selected || !divRef.current) {
      return;
    }
    const scrollYTarget =
      divRef.current.getBoundingClientRect().top + window.pageYOffset - window.innerHeight / 2 + 150;
    scrollTo(window, { top: scrollYTarget, easing: 'ease-in-out', duration: 50 });
    // Scroll to the currently selected blob if compact mode changes since the
    // blob will likely not be in the viewport any longer.
  }, [props.selected, props.compact]);

  if (!feed || !cfe.ApiData.hasData(filteredEntries) || filteredEntries.data.length === 0) {
    return null;
  }
  return (
    <>
      <div className="tw-flex tw-items-center tw-justify-between tw-w-full">
        <SmartFeedLink
          goToFeed={navToFeed}
          apiClient={props.apiClient}
          feedRef={props.feedId}
          className={clsx(
            'tw-text-primary hover:tw-text-primary hover:tw-no-underline',
            props.smHeader ? 'tw-text-[0.9rem] tw-leading-normal' : null,
          )}
        >
          <div>
            <div
              role="button"
              className={clsx(
                props.titleFullWidth ? 'tw-flex' : 'tw-inline-flex',
                'tw-items-center tw-pr-6',
                feed.style?.header_bg_color ? null : 'tw-bg-sky-200',
                'tw-font-semibold',
                props.compact ? 'tw-py-0.5' : 'tw-py-1',
                props.headerClass,
                feed.style?.header_bg_color &&
                  cfe.Color.textColorFromBackgroundColor(feed.style.header_bg_color) === 'light'
                  ? 'tw-text-light hover:tw-text-light'
                  : 'tw-text-dark',
              )}
              style={feed.style?.header_bg_color ? { backgroundColor: feed.style.header_bg_color } : undefined}
            >
              {feed.type['.tag'] === 'save_for_later' ? (
                <>
                  <span className="tw-pr-2">
                    <ClockIcon size="1rem" offsetUp />
                  </span>
                </>
              ) : null}
              {cfe.ApiHelpers.getFeedShortName(feed)}
              {props.subtitle && props.titleFullWidth ? (
                <div className="tw-flex sm:tw-inline-flex tw-ml-4 tw-text-sm tw-py-0.5 tw-px-2">{props.subtitle}</div>
              ) : null}
            </div>
            {props.subtitle && !props.titleFullWidth ? (
              <div className="tw-flex sm:tw-inline-flex tw-ml-4 tw-text-sm tw-py-0.5 tw-px-2">{props.subtitle}</div>
            ) : null}
          </div>
        </SmartFeedLink>
        {props.showFollowButton ? (
          <div className="tw-pr-4">
            <FollowButton feed={feed} sm />
          </div>
        ) : null}
      </div>
      <div ref={divRef} className="tw-overflow-x-scroll">
        <ScrollMenu
          wrapperClassName="ff-scrollbar"
          scrollContainerClassName="tw-py-[2px]" // Prevents outline from being cut off
          separatorClassName={props.compact ? 'tw-mr-[2px]' : 'tw-mr-[4px]'} // Match appearance of StoryDivider
        >
          {filteredEntries.data.slice(0, MAX_ENTRIES).map((nfEntry, index) => (
            <FeedEntryHorizontalItem
              key={nfEntry.entry.entry_id}
              itemId={nfEntry.entry.entry_id}
              apiClient={props.apiClient}
              accountInfo={props.accountInfo}
              feed={feed}
              entry={nfEntry.entry}
              selected={index === kbCursorIndex && props.selected}
              primaryKbOnly={index === kbCursorIndex && props.selected && isExploreOrCpcOpen}
              compact={props.compact}
              agentMode={props.agentMode}
            />
          ))}
        </ScrollMenu>
      </div>
    </>
  );
};

const FeedEntryHorizontalList = React.memo(FeedEntryHorizontalListBase, (prevProps, nextProps) => {
  return (
    prevProps.accountInfo === nextProps.accountInfo &&
    prevProps.feedId === nextProps.feedId &&
    prevProps.selected === nextProps.selected &&
    prevProps.smHeader === nextProps.smHeader &&
    prevProps.compact === nextProps.compact &&
    prevProps.showArchived === nextProps.showArchived &&
    prevProps.filterVideosOnly === nextProps.filterVideosOnly &&
    prevProps.urlToSkip === nextProps.urlToSkip &&
    prevProps.startEntryId === nextProps.startEntryId &&
    prevProps.subtitle === nextProps.subtitle &&
    prevProps.titleFullWidth === nextProps.titleFullWidth &&
    prevProps.headerClass === nextProps.headerClass &&
    prevProps.showFollowButton === nextProps.showFollowButton &&
    prevProps.agentMode === nextProps.agentMode
  );
});

const FeedEntryHorizontalItem = React.memo(
  (props: {
    itemId: string; // Required by horiziontal-scrolling-menu to identify item.
    apiClient: api.SuperegoClient;
    accountInfo: AccountInfoMaybe;
    feed: api.feed.IFeedInfo;
    entry: api.feed.IFeedEntryReference;
    selected: boolean;
    primaryKbOnly: boolean;
    compact: boolean;
    agentMode: boolean;
  }) => {
    const { navToFeed } = useNav();
    const feedEntryOps = useFeedEntryOps(props.apiClient, props.accountInfo, props.feed, props.entry, props.agentMode);
    const feedbackOps = useFeedEntryFeedbackOps(props.apiClient, props.feed, props.entry);

    useKeyPress(
      '1',
      () => {
        // 1: Give-ego modal
        if (feedEntryOps.ego) {
          feedEntryOps.ego();
        }
      },
      !props.selected,
    );
    useKeyPress(
      '2',
      (_, e) => {
        // Prevent '/' from immediately being input into search box.
        e.preventDefault();
        // 2: Send-to modal
        if (feedEntryOps.sendTo) {
          feedEntryOps.sendTo();
        }
      },
      !props.selected,
    );
    useKeyPress(
      '3',
      () => {
        // 3: Save for later
        if (feedEntryOps.saveForLater) {
          feedEntryOps.saveForLater();
        }
      },
      !props.selected,
    );

    const [kbSection, setKbSection] = React.useState<'story' | 'actions'>('story');
    useKeyPress(
      'n',
      () => {
        setKbSection('actions');
      },
      !props.selected || kbSection !== 'story',
      -10,
    );
    useKeyPress(
      'p',
      () => {
        setKbSection('story');
      },
      !props.selected || kbSection !== 'actions',
      -10,
    );

    const visibilityContext = React.useContext(VisibilityContext);
    React.useEffect(() => {
      if (props.selected) {
        const item = visibilityContext.getItemById(props.entry.entry_id);
        if (item) {
          visibilityContext.scrollToItem(item, undefined, 'start', undefined, { duration: 80 });
        }
      }
    }, [props.selected]);

    const md = cfe.ApiHelpers.getEntryMetadata(props.entry);
    const portraitVideoImage =
      md['.tag'] === 'ready' &&
      !!md.image &&
      md.image.height > md.image.width &&
      md.content_type['.tag'] === 'video' &&
      !!md.content_type.portrait;

    const leftSwipeFeeds = React.useMemo(() => {
      const leftSwipeFeedsToOmit = new Set([props.feed.feed_id]);
      const leftSwipeFeedsTemp = [];
      if (props.entry.via && !leftSwipeFeedsToOmit.has(props.entry.via.feed.feed_id)) {
        leftSwipeFeedsToOmit.add(props.entry.via.feed.feed_id);
        leftSwipeFeedsTemp.push(props.entry.via.feed);
      }
      // SEP 125: Natural topic
      if (props.entry.natural_topic && !leftSwipeFeedsToOmit.has(props.entry.natural_topic.feed_id)) {
        leftSwipeFeedsToOmit.add(props.entry.natural_topic.feed_id);
        leftSwipeFeedsTemp.push(props.entry.natural_topic);
      }
      // Debut feed
      if (
        md['.tag'] === 'ready' &&
        md.hgc_info?.debut_feed &&
        !leftSwipeFeedsToOmit.has(md.hgc_info.debut_feed.feed_id)
      ) {
        leftSwipeFeedsToOmit.add(md.hgc_info.debut_feed.feed_id);
        leftSwipeFeedsTemp.push(md.hgc_info.debut_feed);
      }
      // SEP 153: Works feed
      if (props.entry.works_feed && !leftSwipeFeedsToOmit.has(props.entry.works_feed.feed_id)) {
        leftSwipeFeedsToOmit.add(props.entry.works_feed.feed_id);
        leftSwipeFeedsTemp.push(props.entry.works_feed);
      }
      // SEP 169: Channel feed
      if (
        !props.entry.works_feed &&
        md['.tag'] === 'ready' &&
        md.channel &&
        md.channel.feed &&
        md.channel.feed.feed_id !== props.feed.feed_id &&
        !leftSwipeFeedsToOmit.has(md.channel.feed.feed_id)
      ) {
        leftSwipeFeedsToOmit.add(md.channel.feed.feed_id);
        leftSwipeFeedsTemp.push(md.channel.feed);
      }
      return leftSwipeFeedsTemp;
    }, [props.feed, props.entry]);

    const showImage = !props.compact && md['.tag'] === 'ready' && md.image;

    return (
      <HoverTouchWheelMenu
        entryId={props.entry.entry_id}
        navToFeed={navToFeed}
        selected={props.selected}
        disableKb={props.primaryKbOnly}
        disableKbSlide={kbSection === 'story'}
        compact={props.compact}
        onArchive={feedEntryOps.archive}
        feedbackOps={feedbackOps}
        hideFeedbackActions={props.feed.type['.tag'] === 'notif' || props.feed.type['.tag'] === 'rec_for_you'}
        leftSwipeFeeds={leftSwipeFeeds}
        // FeedEntryImage forces this item to have z-index=10. It needs it
        // to overlay the image on top of its placeholder. When horiz items
        // overlap each other due to the sliding action, they need to have
        // a consistent z-index to avoid images floating above the
        // intersecting entries.
        className="tw-bg-canvas tw-h-full tw-flex tw-z-10"
        maxWidth={portraitVideoImage ? 210 : 270}
        childrenClassName={clsx(
          'tw-bg-primary hover:tw-bg-highlight hover:tw-story-outline',
          !props.compact ? 'tw-shadow-sm' : null,
          'tw-rounded-lg tw-overflow-hidden',
          portraitVideoImage ? 'tw-w-[210px]' : 'tw-w-[270px]',
        )}
        swipeSurface={
          <div
            className={clsx(
              'tw-pt-2 tw-pb-2',
              props.compact ? 'tw-px-2' : 'tw-px-4',
              props.selected ? 'kb-bar kb-sel' : 'kb-bar',
            )}
          >
            <FeedEntryActions
              feed={props.feed}
              entry={props.entry}
              actions={feedEntryOps}
              agentMode={props.agentMode}
            />
          </div>
        }
      >
        <div
          role="button"
          className={clsx(
            'tw-h-full',
            'tw-flex tw-flex-col tw-overflow-hidden',
            props.compact ? 'tw-pt-2' : 'tw-pt-4',
            props.selected && kbSection === 'story' ? 'kb-bar kb-sel' : 'kb-bar',
          )}
          onClick={e => feedEntryOps.open(e.ctrlKey || e.metaKey)}
          onAuxClick={e => {
            if (e.button === 1) {
              // Middle mouse button
              feedEntryOps.open(true);
            }
          }}
        >
          <div className="tw-flex tw-flex-col tw-grow tw-gap-y-2">
            <div className={clsx('tw-flex tw-flex-col tw-gap-y-1', props.compact ? 'tw-px-1' : 'tw-px-3')}>
              <StoryUpperMatter
                apiClient={props.apiClient}
                feed={props.feed}
                entry={props.entry}
                goToFeed={navToFeed}
                onOpenStory={feedEntryOps.open}
                compact={props.compact}
                useTitleBadge={false}
                clampTitle
              />
              <StoryLowerMatter
                apiClient={props.apiClient}
                feed={props.feed}
                entry={props.entry}
                goToFeed={navToFeed}
                onOpenStory={feedEntryOps.open}
                compact={props.compact}
                showMiniBadgeLine
                showFromLine={false}
              />
            </div>
            {showImage ? (
              <FeedEntryImage
                md={md}
                className="tw-grow tw-flex tw-items-center"
                customHeightClassName={portraitVideoImage ? 'tw-max-h-[190px]' : 'tw-max-h-[140px]'}
              />
            ) : null}
          </div>
        </div>
        <FeedEntryLeftSwipeTriangles
          count={leftSwipeFeeds.length}
          th={showImage && md['.tag'] === 'ready' ? md.image?.th : undefined}
          compact={props.compact}
        />
      </HoverTouchWheelMenu>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.accountInfo === nextProps.accountInfo &&
      prevProps.feed === nextProps.feed &&
      prevProps.entry === nextProps.entry &&
      prevProps.selected === nextProps.selected &&
      prevProps.primaryKbOnly === nextProps.primaryKbOnly &&
      prevProps.compact === nextProps.compact &&
      prevProps.agentMode === nextProps.agentMode
    );
  },
);

export default FeedEntryHorizontalList;
