export function getCookie(name: string): string | null {
  const value = '; ' + document.cookie;
  const parts = value.split('; ' + name + '=');
  if (parts.length === 2) {
    return parts.pop()!.split(';').shift()!;
  } else {
    return null;
  }
}

export function getSearchParameter(search: string, param: string): string | null {
  if (search === '') {
    return null;
  }
  search = search.replace(/^\?/, '');
  for (const group of search.split('&')) {
    const [key, val] = group.split('=');
    if (key === param) {
      return val;
    }
  }
  return null;
}

export function removeSearchParameter(search: string, param: string): string {
  if (search === '') {
    return '';
  }
  search = search.replace(/^\?/, '');
  const groups = [];
  for (const group of search.split('&')) {
    const [key] = group.split('=');
    if (key === param) {
      continue;
    }
    groups.push(group);
  }
  if (groups.length > 0) {
    return '?' + groups.join('&');
  } else {
    return '';
  }
}

/**
 * Returns a URL that's guaranteed to have the scheme (http / https) specified.
 *
 * If the URL is lacking a scheme or starts with `//`, we prepend `https://`.
 * This is more likely to work than not, but unfortunately, some users might
 * get burned by the assumption.
 *
 * @param url The URL to add a scheme to if unspecified.
 */
export function addUrlScheme(url: string): string {
  if (url.startsWith('//')) {
    return `https:${url}`;
  } else if (!url.startsWith('http://') && !url.startsWith('https://') && !url.startsWith('ego://')) {
    // This is more likely to work than not, so let's do this for the ease of use.
    // Unfortunately, some users might get burned by URLs that only work via http.
    return `https://${url}`;
  } else {
    return url;
  }
}

export function base64ToByteArray(base64: string): Uint8Array {
  // Decode Base64 string to ASCII string
  const binaryString = atob(base64);
  // Create a Uint8Array with the same length as the binary string
  const bytes = new Uint8Array(binaryString.length);
  // Convert each character to its ASCII value and store in Uint8Array
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}
