import { googleLogout } from '@react-oauth/google';
import clsx from 'clsx';
import { AnimatePresence, m } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';

import { router } from '../index';

import FeedSearchInput from './FeedSearchInput';
import useApiClient from './hooks/useApiClient';
import useApiDo from './hooks/useApiDo';
import useInboxUnreadReconciled from './hooks/useInboxUnreadReconciled';
import useIsAssumedIdentity from './hooks/useIsAssumedIdentity';
import useToast from './hooks/useToast';
import useUserMeInternal from './hooks/useUserMeInternal';
import AboutIcon from './icon/AboutIcon';
import ChromeIcon from './icon/ChromeIcon';
import DiscoverIcon from './icon/DiscoverIcon';
import EgoSlice from './icon/EgoSlice';
import HomeIcon from './icon/HomeIcon';
import InboxIcon from './icon/InboxIcon';
import KeyboardIcon from './icon/KeyboardIcon';
import LibraryIcon from './icon/LibraryIcon';
import MenuIcon from './icon/MenuIcon';
import MobileIcon from './icon/MobileIcon';
import MoonIcon from './icon/MoonIcon';
import MoreIcon from './icon/MoreIcon';
import SearchIcon from './icon/SearchIcon';
import SignInIcon from './icon/SignInIcon';
import SunIcon from './icon/SunIcon';
import KeyboardShortcutsModal from './KeyboardShortcutsModal';
import { useKeyPress } from './KeyPressContext';
import Button from './lib/Button';
import LayoutLine from './lib/LayoutLine';
import Modal from './lib/Modal';
import TextInput from './lib/TextInput';
import { useAgentMode, useAgentModeSetter } from './LocalStorageReduxBridge';
import Logo from './Logo';
import { useModalListener, useModalManager } from './ModalManagerContext';
import Pagelet from './Pagelet';
import { useThemeMode, useThemeModeSetter } from './ThemeModeGate';

const LayoutWithSidebar = () => {
  const { setToast } = useToast();
  const navigate = router.navigate;
  const accountInfo = useUserMeInternal();
  const { pushModal } = useModalManager();

  const gPressed = React.useRef(0);
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);

  // Key combos like "gh" aren't directly supported so we approximate it here
  // by timestamping when "g" was last pressed.
  useKeyPress(
    'g',
    () => {
      if (new Date().getTime() - gPressed.current < 3000) {
        gPressed.current = 0;
        goHome(true);
      } else {
        gPressed.current = new Date().getTime();
      }
    },
    false,
    undefined,
    { propagate: true },
  );
  useKeyPress(
    'b',
    () => {
      if (new Date().getTime() - gPressed.current < 3000) {
        gPressed.current = 0;
        if (!accountInfo) {
          setToast({ header: 'Sign in to go to your inbox', icon: 'frown' });
        } else if (window.location.pathname !== '/inbox') {
          navigate('/inbox');
        } else {
          setToast({ header: 'bro, you alright?', body: 'Do you even know where you are?', icon: 'dumbbell' });
        }
      }
    },
    undefined,
    10,
    { propagate: true },
  );
  useKeyPress(
    'l',
    () => {
      if (new Date().getTime() - gPressed.current < 3000) {
        gPressed.current = 0;
        if (!accountInfo) {
          setToast({ header: 'Sign in to go to your library', icon: 'frown' });
        } else if (window.location.pathname !== '/library') {
          navigate('/library');
        } else {
          setToast({ header: 'bro, you alright?', body: 'Do you even know where you are?', icon: 'dumbbell' });
        }
      }
    },
    undefined,
    10,
    { propagate: true },
  );
  useKeyPress('/', (_, e) => {
    // Prevent '/' from immediately being input into search box.
    e.preventDefault();
    pushModal({ kind: 'search' }, { stackOk: true });
  });
  useKeyPress('?', () => {
    // ?: Toggle keyboard shortcuts screen.
    pushModal(
      {
        component: modalProps => <KeyboardShortcutsModal {...modalProps} />,
        dupKey: 'kbshortcuts',
        kind: 'generic',
      },
      { stackOk: true },
    );
  });
  const goHome = (kbTriggered: boolean) => {
    const homeLoc = '/';
    if (window.location.pathname !== homeLoc) {
      navigate(homeLoc);
    } else if (kbTriggered) {
      setToast({ header: 'bro, you alright?', body: 'Do you even know where you are?', icon: 'dumbbell' });
    }
  };
  return (
    <div className="tw-bg-canvas tw-grid tw-grid-cols-12">
      {/* This is an absolute-positioned light/dark mode toggle */}
      <DarkModeToggle />
      <div className="tw-col-span-12 sm:tw-col-span-1 md:tw-col-span-1 lg:tw-col-span-2 xl:tw-col-span-2">
        {/* This is the sidebar grid column(s) */}
        <div
          className={
            // Make the sidebar sticky so that it doesn't scroll with the page,
            // but also allow it to scroll if it gets lengthy.
            'tw-sticky tw-top-0 sm:tw-h-screen sm:tw-overflow-y-scroll tw-z-40'
          }
        >
          <div className="tw-flex tw-flex-col">
            <div className="tw-flex tw-w-full tw-mt-2 max-sm:tw-justify-between max-sm:tw-pr-2">
              {/* This is the line for the logo + name (optional) + mobile menu (optional) */}
              <a
                href="/"
                className={clsx(
                  'tw-flex tw-items-center sm:tw-w-full max-lg:tw-justify-center',
                  'tw-py-1.5',
                  'tw-ml-1 lg:tw-ml-4 tw-mr-1',
                  'tw-whitespace-nowrap hover:tw-no-underline',
                )}
                tabIndex={-1}
                onClick={(e: React.MouseEvent<any>) => {
                  if (e.ctrlKey || e.metaKey) {
                    return;
                  }
                  e.preventDefault();
                  goHome(false);
                }}
              >
                <div
                  role="button"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsMobileDrawerOpen(!isMobileDrawerOpen);
                  }}
                  className={clsx(
                    'tw-flex sm:tw-hidden tw-items-center tw-justify-center tw-text-primary',
                    'tw-w-10 tw-h-10 tw-mr-2',
                    'tw-border-2 tw-rounded-lg tw-border-solid tw-border-layout-line-light dark:tw-border-layout-line-dark',
                  )}
                >
                  <MenuIcon size="1.25rem" />
                </div>
                <span className="tw-text-primary dark:tw-text-neutral-700">
                  <Logo />
                </span>
                <span className="tw-text-primary tw-font-semibold tw-text-[1.4rem] tw-ml-2 tw-hidden lg:tw-inline tw-font-inter">
                  Superego
                </span>
              </a>
            </div>
            <div className="tw-hidden sm:tw-block tw-w-full">
              <Navbar />
            </div>
            <div>
              <AnimatePresence>
                {isMobileDrawerOpen ? <SidebarDrawer key="drawer" close={() => setIsMobileDrawerOpen(false)} /> : null}
              </AnimatePresence>
            </div>
          </div>
        </div>
      </div>
      <div className="tw-col-span-12 sm:tw-col-span-11 md:tw-col-span-11 lg:tw-col-span-10 xl:tw-col-span-10 tw-container tw-mx-auto">
        <React.Suspense fallback={<Pagelet title="..." skipHelmet working noBgPrimary />}>
          <Outlet />
        </React.Suspense>
      </div>
    </div>
  );
};

const DarkModeToggle = () => {
  const themeMode = useThemeMode();
  const themeSet = useThemeModeSetter();
  const modalState = useModalListener();
  const location = useLocation();
  // If a modal is shown, hide the dark-mode-toggle because it's overlapped by
  // the modal close X-icon.
  if (modalState || location.pathname.startsWith('/e/')) {
    return null;
  }
  return (
    <div
      role="button"
      className="tw-z-[45] tw-absolute tw-right-3 tw-top-2 tw-text-muted hover:tw-text-primary"
      onClick={() => themeSet(themeMode === 'dark' ? 'light' : 'dark')}
    >
      {themeMode === 'dark' ? (
        <>
          <MoonIcon size="1.3rem" />
        </>
      ) : (
        <>
          <SunIcon size="1.5rem" />
        </>
      )}
    </div>
  );
};

const SidebarDrawer = (props: { close: () => void }) => (
  <m.div
    className={clsx(
      'tw-absolute sm:tw-hidden tw-top-[7rem] tw-bg-canvas tw-min-w-[40%] sm:tw-min-w-fit',
      'tw-border-2 tw-border-solid tw-border-l-0 tw-border-layout-line-light dark:tw-border-layout-line-dark',
    )}
    initial={{ opacity: 0, left: -100 }}
    animate={{ opacity: 1, left: 0 }}
    exit={{ opacity: 0, left: -100 }}
    transition={{ duration: 0.2 }}
  >
    <Navbar selectCb={props.close} />
  </m.div>
);

const Navbar = (props: { selectCb?: () => void }) => {
  const isAssumedIdentity = useIsAssumedIdentity();
  const accountInfo = useUserMeInternal();
  const { pushModal } = useModalManager();
  const unread = useInboxUnreadReconciled();

  // Refresh inbox count when tab is activated.
  // NOTE: While better for the user experience, this aggressive refresh can be
  // made more conservative if necessary.
  useEffect(() => {
    if (!accountInfo) {
      return;
    }
    const refreshInboxUnread = () => {
      unread.unreadRefresh();
    };
    window.addEventListener('focus', refreshInboxUnread);
    return () => {
      window.removeEventListener('focus', refreshInboxUnread);
    };
    // It's important to recalculate as the refresh function will change.
  }, [unread]);

  // HACK: Could not figure out how to use AnimatePresence with staggerChlidren
  // so closing state has to be handled explicitly.
  const [subnavState, setSubnavState] = useState<SubnavState>('closed');
  return (
    <div className="tw-flex tw-flex-col tw-w-full tw-pt-4">
      {!accountInfo ? (
        <a href="/alterego" title="Join" className="!tw-no-underline">
          <NavItem icon={<EgoSlice size={24} />} text="Join" className="tw-bg-gray-200 dark:tw-bg-gray-700" />
        </a>
      ) : null}
      {!accountInfo ? (
        <a href="/login" title="signin" className="!tw-no-underline">
          <NavItem icon={<SignInIcon size={24} />} text="Sign In" />
        </a>
      ) : null}
      {!accountInfo ? (
        <a href="/about" title="About Superego" className="!tw-no-underline">
          <NavItem icon={<AboutIcon size={24} />} text="About" />
        </a>
      ) : null}
      {accountInfo ? (
        <>
          <Link to="/" title="Home" className="!tw-no-underline" onClick={props.selectCb}>
            <NavItem icon={<HomeIcon size="1.6rem" />} text="Home" />
          </Link>
          <Link to="/inbox" title="Inbox" className="!tw-no-underline" onClick={props.selectCb}>
            <NavItem icon={<InboxIcon size="1.5rem" />} text="Inbox" badge={unread.unreadCountReconciled?.toString()} />
          </Link>
        </>
      ) : null}
      <Link to="/discover" title="Discover" className="!tw-no-underline" onClick={props.selectCb}>
        <NavItem icon={<DiscoverIcon size="1.5rem" />} text="Discover" />
      </Link>
      <NavItem
        icon={<SearchIcon size={24} />}
        text="Search"
        onClick={() => {
          pushModal({ kind: 'search' });
          if (props.selectCb) {
            props.selectCb();
          }
        }}
      />
      {accountInfo ? (
        <Link
          to={'/' + accountInfo.username}
          title="Your ego feed"
          className="!tw-no-underline"
          onClick={props.selectCb}
        >
          <NavItem
            icon={
              <m.div variants={{ hover: { rotate: -45 } }}>
                <EgoSlice size={24} />
              </m.div>
            }
            text={accountInfo.name}
            // Highlight if identity is assumed so that agents don't forget.
            className={isAssumedIdentity ? 'tw-bg-purple-500' : undefined}
            lineClamp
          />
        </Link>
      ) : null}
      {accountInfo ? (
        <Link to="/library" title="Library" className="!tw-no-underline" onClick={props.selectCb}>
          <NavItem icon={<LibraryIcon size={24} />} text="Library" />
        </Link>
      ) : null}
      <div className="tw-block sm:tw-hidden lg:tw-block">
        <NavItem
          icon={
            <m.div animate={{ rotate: subnavState === 'open' ? 180 : 0 }} transition={{ duration: 0.4 }}>
              <MoreIcon size={24} />
            </m.div>
          }
          text="More"
          onClick={() =>
            setSubnavState(subnavState === 'open' ? 'closing' : subnavState === 'closed' ? 'open' : 'closed')
          }
        />
      </div>
      <div className="tw-hidden sm:tw-block lg:tw-hidden">
        <NavItem
          icon={
            <m.div animate={{ rotate: subnavState === 'open' ? 180 : 0 }} transition={{ duration: 0.4 }}>
              <MoreIcon size={24} />
            </m.div>
          }
          text="More"
          onClick={() => {
            pushModal({
              component: modalProps => (
                <Modal.Container {...modalProps} show={true} containerClassName="!tw-max-w-fit">
                  <Modal.Body gutter>
                    <Subnav subnavState="open" setSubnavState={setSubnavState} selectCb={() => modalProps.close()} />
                  </Modal.Body>
                </Modal.Container>
              ),
              dupKey: 'subnav',
              kind: 'generic',
            });
          }}
        />
      </div>
      <div className="tw-block md:tw-hidden lg:tw-block">
        <Subnav subnavState={subnavState} setSubnavState={setSubnavState} selectCb={props.selectCb} />
      </div>
    </div>
  );
};

type SubnavState = 'open' | 'closed' | 'closing';

const Subnav = (props: {
  subnavState: SubnavState;
  setSubnavState: (val: SubnavState) => void;
  selectCb?: () => void;
}) => {
  const isAssumedIdentity = useIsAssumedIdentity();
  const accountInfo = useUserMeInternal();
  const themeMode = useThemeMode();
  const themeSet = useThemeModeSetter();
  const agentMode = useAgentMode();
  const agentModeSetter = useAgentModeSetter();
  const { pushModal } = useModalManager();
  useKeyPress('m', () => {
    // Toggle agent mode
    if (accountInfo?.is_staff) {
      agentModeSetter(!agentMode);
    }
  });
  return (
    <>
      {props.subnavState === 'open' || props.subnavState === 'closing' ? (
        <m.div
          variants={{
            closed: {
              transition: {
                staggerChildren: 0.01,
                staggerDirection: -1,
              },
            },
            open: {
              transition: {
                staggerChildren: 0.01,
              },
            },
          }}
          onAnimationComplete={() => {
            if (props.subnavState === 'closing') {
              props.setSubnavState('closed');
            }
          }}
          animate={props.subnavState === 'open' ? 'open' : 'closed'}
          initial="closed"
        >
          <SubnavItem
            onClick={() => {
              themeSet(themeMode === 'dark' ? 'light' : 'dark');
              if (props.selectCb) {
                props.selectCb();
              }
            }}
          >
            {themeMode === 'dark' ? (
              <>
                Light Mode <SunIcon size="1.125rem" offsetUp />
              </>
            ) : (
              <>
                Dark Mode <MoonIcon size="1.125rem" className="-tw-mt-2" />
              </>
            )}
          </SubnavItem>
          {/* Force full page load for zendesk injection into HTML template */}
          {accountInfo ? <SubnavItemLinkExternal text="Subscription" href="/subscription" /> : null}
          {accountInfo ? <SubnavItemLink text="Your Follows" to="/follows" selectCb={props.selectCb} /> : null}
          <SubnavItemLink to="/get-app" selectCb={props.selectCb}>
            Get <MobileIcon size="1rem" offsetUp /> App
          </SubnavItemLink>
          <SubnavItemLink to="/get-extension" selectCb={props.selectCb}>
            Get <ChromeIcon size="1rem" offsetUp /> Extension
          </SubnavItemLink>
          {accountInfo ? (
            <>
              <SubnavItemLink to="/account" selectCb={props.selectCb}>
                Account
              </SubnavItemLink>
              <SubnavItemLink to="/author" selectCb={props.selectCb}>
                Author
              </SubnavItemLink>
              <SubnavItemLinkExternal href="/about">About</SubnavItemLinkExternal>
            </>
          ) : null}
          <SubnavItemLinkExternal href="https://github.com/superego-ai/superego-api-spec">
            Developer
          </SubnavItemLinkExternal>
          <SubnavItem
            onClick={() => {
              pushModal({
                component: modalProps => <KeyboardShortcutsModal {...modalProps} />,
                dupKey: 'kbshortcuts',
                kind: 'generic',
              });
              if (props.selectCb) {
                props.selectCb();
              }
            }}
          >
            Keyboard <KeyboardIcon size="1.2rem" />
          </SubnavItem>
          {accountInfo?.is_staff || isAssumedIdentity ? (
            <>
              <SubnavDivider />
              {accountInfo?.is_staff ? (
                <>
                  <SubnavItem
                    text={agentMode ? 'User Mode' : 'Agent Mode'}
                    onClick={() => {
                      agentModeSetter(!agentMode);
                      if (props.selectCb) {
                        props.selectCb();
                      }
                    }}
                  />
                  <SubnavItemLink to="/z/agent" text="Agent Menu" selectCb={props.selectCb} />
                  <SubnavItemLink to="/onboarding" text="Onboarding" selectCb={props.selectCb} />
                </>
              ) : null}
              {isAssumedIdentity ? (
                <RevertIdentityNavItem />
              ) : (
                <SubnavItem
                  text="Assume Identity"
                  onClick={() => {
                    pushModal({
                      component: modalProps => <AssumeIdentityModal {...modalProps} />,
                      dupKey: 'assume-identity',
                      kind: 'generic',
                    });
                  }}
                />
              )}
            </>
          ) : null}
          {accountInfo ? (
            <>
              <SubnavDivider />
              <LogoutNavItem />
            </>
          ) : null}
        </m.div>
      ) : null}
    </>
  );
};

const NavItem = (props: {
  icon: React.ReactNode;
  text: string;
  onClick?: () => void;
  className?: string;
  lineClamp?: boolean;
  badge?: string;
}) => (
  <m.div
    role="button"
    whileHover="hover"
    className={clsx(
      'tw-flex tw-items-center tw-justify-start sm:max-lg:tw-justify-center',
      // Bound size of nav item since available space in grid col can be very
      // large on a wide screen.
      'sm:tw-max-w-[15rem]',
      'tw-py-3 tw-pl-2 md:tw-pl-3 lg:tw-pl-6 tw-pr-2',
      'tw-text-lg tw-font-medium',
      'hover:!tw-bg-yellow-light dark:hover:!tw-bg-yellow-dark hover:tw-text-primary tw-text-primary',
      props.className,
    )}
    onClick={props.onClick}
  >
    <div className="tw-relative">
      <m.div variants={{ hover: { scale: 1.05 } }}>{props.icon}</m.div>
      {props.badge ? (
        <m.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.2 }}
          className={clsx(
            'tw-absolute tw-top-0 tw-right-0 tw-hidden sm:max-lg:tw-flex tw-items-center tw-justify-center tw-text-sm tw-h-4 tw-w-4',
            'tw-bg-red-400 dark:tw-bg-red-800 tw-rounded-full',
          )}
        >
          {props.badge}
        </m.div>
      ) : null}
    </div>
    <span className="!tw-line-clamp-1 sm:!tw-hidden lg:!tw-line-clamp-1 tw-ml-2 tw-grow">{props.text}</span>
    {props.badge ? (
      <m.div
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.2 }}
        className={clsx(
          'sm:max-lg:tw-hidden tw-flex tw-items-center tw-justify-center tw-text-sm tw-h-6 tw-w-6',
          'tw-bg-red-400 dark:tw-bg-red-800 tw-rounded-full',
        )}
      >
        {props.badge}
      </m.div>
    ) : null}
  </m.div>
);

interface SubnavItemProps {
  icon?: React.ReactNode;
  text?: string;
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  className?: string;
}

const SubnavItem = (props: SubnavItemProps) => (
  <m.div
    role="button"
    className={clsx(
      'tw-flex tw-items-center tw-justify-start',
      // Bound size of nav item since available space in grid col can be very
      // large on a wide screen.
      'sm:tw-max-w-[15rem]',
      'tw-py-1 tw-pl-2 md:tw-pl-3 lg:tw-pl-6 tw-pr-2',
      'tw-text-base tw-font-normal',
      'hover:!tw-bg-yellow-light dark:hover:!tw-bg-yellow-dark hover:tw-text-primary tw-text-primary',
      props.className,
    )}
    onClick={props.onClick}
    variants={{
      closed: {
        opacity: 0,
        x: -20,
      },
      open: {
        opacity: 1,
        x: 0,
      },
    }}
    transition={{
      opacity: {
        duration: 0.1,
      },
      x: {
        duration: 0.15,
      },
    }}
  >
    <span className="tw-ml-2">{props.text ?? props.children}</span>
    {props.icon}
  </m.div>
);

const SubnavItemLink = (props: SubnavItemProps & { to: string; selectCb?: () => void }) => {
  const { to, ...rest } = props;
  return (
    <Link to={props.to} className="hover:tw-no-underline" onClick={props.selectCb}>
      <SubnavItem {...rest} />
    </Link>
  );
};

const SubnavItemLinkExternal = (props: SubnavItemProps & { href: string }) => {
  const { href, ...rest } = props;
  return (
    <Link to={href} reloadDocument className="hover:tw-no-underline">
      <SubnavItem {...rest} />
    </Link>
  );
};

const SubnavDivider = () => (
  <m.div
    className="tw-pl-2 md:tw-pl-3 lg:tw-pl-6 tw-pr-2 tw-max-w-[10rem]"
    variants={{
      closed: {
        opacity: 0,
        x: -20,
      },
      open: {
        opacity: 1,
        x: 0,
      },
    }}
    transition={{
      opacity: {
        duration: 0.1,
      },
      x: {
        duration: 0.15,
      },
    }}
  >
    <LayoutLine className="tw-my-2 tw-w-full" />
  </m.div>
);

const AssumeIdentityModal = (props: { close: () => void }) => {
  const [loginId, setLoginId] = useState('');
  const searchRef = React.useRef<HTMLInputElement | null>(null);
  const modalRef = React.useRef<HTMLDivElement | null>(null);
  const loginInputRef = React.useRef<HTMLInputElement | null>(null);

  const apiClient = useApiClient();
  const { apiDo: apiAccountWebSessionAssume } = useApiDo(apiClient, apiClient.accountWebSessionAssume);

  useKeyPress(
    ['u', 'Escape'],
    () => {
      props.close();
    },
    false,
    10,
  );
  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.focus();
    }
  }, [searchRef.current]);

  return (
    <Modal.Container ref={modalRef} show={true} close={props.close}>
      <Modal.Header>
        <Modal.Heading1>Assume Identity</Modal.Heading1>
      </Modal.Header>
      <Modal.Body>
        <div className="tw-mb-4">
          <FeedSearchInput
            ref={searchRef}
            kbActive={true}
            targetWindow={modalRef}
            peopleOnly
            onClickOverride={feed => {
              // `publisher` should be set since only ego feeds are returned.
              if (!feed.publisher) {
                return false;
              }
              apiAccountWebSessionAssume(
                { login_id: feed.publisher.username },
                {
                  onResult: () => {
                    window.location.href = '/';
                  },
                },
              );
            }}
          />
        </div>
        <Modal.Gutter>
          <LayoutLine />
          <form
            className="tw-flex tw-flex-col tw-gap-y-4"
            onSubmit={e => {
              e.preventDefault();
              apiAccountWebSessionAssume(
                { login_id: loginId },
                {
                  onResult: () => {
                    window.location.href = '/';
                  },
                },
              );
            }}
          >
            <TextInput
              ref={loginInputRef}
              type="text"
              placeholder="Username / Email"
              onChange={e => setLoginId(e.currentTarget.value)}
              value={loginId}
              required
            />
            <Button block type="submit">
              Assume
            </Button>
          </form>
        </Modal.Gutter>
      </Modal.Body>
    </Modal.Container>
  );
};

const RevertIdentityNavItem = () => {
  const apiClient = useApiClient();
  const { apiDo: apiAccountWebSessionRevert } = useApiDo(apiClient, apiClient.accountWebSessionRevert);
  return (
    <SubnavItem
      text="Revert Identity"
      onClick={() => {
        apiAccountWebSessionRevert(undefined, {
          onResult: () => {
            window.location.href = '/';
          },
        });
      }}
    />
  );
};

const LogoutNavItem = () => {
  const apiClient = useApiClient();
  const { apiDo: apiAccountWebSessionLogout } = useApiDo(apiClient, apiClient.accountWebSessionLogout);
  return (
    <SubnavItem
      text="Log out"
      onClick={() => {
        apiAccountWebSessionLogout(undefined, {
          onResult: () => {
            googleLogout();
            window.location.href = '/?alert=logout';
          },
        });
      }}
    />
  );
};

export default LayoutWithSidebar;
